<template>
    <div v-if="errors.length" class="validator-alert p-error">
        <div v-for="error in errors" :key="error.$uid">{{ error.$message }}</div>
    </div>
</template>

<script>
export default {
    name: 'ValidationError',
    props: {
        errors: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.validator-alert {
    text-align: start;
    font-size: 12px;
    margin-top: -10px; 
    margin-bottom: 10px;
    padding-left: 28%;
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .validator-alert {
        padding-left: 5%;
    }
}
</style>
