<template>
    <Navbar />
    <div class="header">
        <BackButton />
        <h3>Editar Dívida</h3>
    </div>
    <div id="content">
        <TabView id="tabview">
            <TabPanel header="Dados">
                <div id="form-container">
                    <form @submit.prevent="UpdateDebt">
                        <div class="input-group">
                            <label for="desc">Descrição:</label>
                            <InputText v-model="desc" type="text" id="desc" placeholder="VPS 6GB" />
                        </div>
                        <ValidationError v-if="v$.desc.$error" :errors="v$.desc.$errors" />

                        <div class="input-group">
                            <label for="amount">Valor:</label>
                            <InputNumber v-model="amount" inputId="minmaxfraction" :minFractionDigits="2"
                                :disabled="this.status !== 'Pendente' || this.selected_recurrence == 'installment'"
                                :maxFractionDigits="2" placeholder="120,00" />
                        </div>
                        <ValidationError v-if="v$.amount.$error" :errors="v$.amount.$errors" />

                        <div class="input-group">
                            <label for="recurrence">Recorrência:</label>
                            <Dropdown v-model="selected_recurrence" :options="recurrences" optionLabel="name"
                                optionValue="code" placeholder="Selecione a Recorrência" class="w-full md:w-14rem"
                                disabled />
                        </div>
                        <ValidationError v-if="v$.selected_recurrence.$error"
                            :errors="v$.selected_recurrence.$errors" />

                        <div class="input-group" v-show="selected_recurrence == 'installment'">
                            <label for="installment_count">Parcelas:</label>
                            <InputNumber v-model="installment_count" mode="decimal" showButtons :min="1" :max="30"
                                :disabled="this.status !== 'Pendente'" placeholder="1 a 30" />
                        </div>
                        <ValidationError v-if="selected_recurrence == 'installment' && v$.installment_count.$error"
                            :errors="v$.installment_count.$errors" />

                        <div class="input-group">
                            <label for="emission">Emissão:</label>
                            <Calendar v-model="emission" placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy" disabled />
                        </div>
                        <ValidationError v-if="v$.emission.$error" :errors="v$.emission.$errors" />

                        <div class="input-group">
                            <label for="client">Cliente:</label>
                            <InputText v-model="selected_client" type="text" id="desc" placeholder="VPS 6GB" disabled />
                        </div>
                        <ValidationError v-if="v$.selected_client.$error" :errors="v$.selected_client.$errors" />

                        <div class="input-group">
                            <label for="due">Vencimento:</label>
                            <Calendar v-if="selected_recurrence == 'once'" v-model="due_date" placeholder="mm/dd/aaaa"
                                dateFormat="dd/mm/yy" />
                            <InputNumber v-else v-model="due_day" mode="decimal" showButtons :min="1" :max="31"
                                :disabled="this.status !== 'Pendente'" placeholder="1 a 31" />
                        </div>
                        <div v-if="show_due_alert" id="due-alert">
                            <span class="p-error">Mudanças no dia de pagamento serão aplicadas nas faturas somente a
                                partir do próximo mês</span>
                        </div>
                        <ValidationError v-if="v$.due_day.$error" :errors="v$.due_day.$errors" />
                        <ValidationError v-if="v$.due_date.$error" :errors="v$.due_date.$errors" />

                        <!-- <div class="input-group">
                        <label for="fee">Juros:</label>
                        <InputNumber v-model="fee" inputId="minmaxfraction" :minFractionDigits="2" :maxFractionDigits="2" :disabled="this.status !== 'Pendente'"/>
                        </div> -->

                        <div class="input-group">
                            <label for="recurrence">Tags:</label>
                            <MultiSelect v-model="selected_tag" display="chip" :options="tags" optionValue="name"
                                optionLabel="name" placeholder="Selecione a Categoria" class="w-full md:w-20rem" />
                        </div>
                        <ValidationError v-if="v$.selected_tag.$error" :errors="v$.selected_tag.$errors" />

                        <div class="input-group">
                            <label for="status">Estado:</label>
                            <InputText v-model="status" type="text" placeholder="Pendente" disabled />
                        </div>

                        <div>
                            <Button class="form-btn" label="Arquivar" severity="warning" @click="ConfirmDebtDisable()"
                                :disabled="this.status !== 'Pendente'" />
                            <Button type="submit" class="form-btn" label="Atualizar" severity="info"
                                :disabled="btn_disabled" />
                        </div>
                    </form>
                </div>
            </TabPanel>
            <TabPanel header="Faturas">
                <div class="card">
                    <DataTable :value="instances" :rows="25" paginator :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem">
                        <Column field="debt_id" header="ID" sortable></Column>
                        <Column field="debt_invoice" header="Invoice" style="display: none"></Column>
                        <Column field="debt_desc" header="Descrição" sortable></Column>
                        <Column field="debt_amount" header="Valor" sortable>
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.debt_amount) }}
                            </template>
                        </Column>
                        <Column field="debt_due" header="Vencimento" sortable></Column>
                        <Column field="debt_status" header="Estado" sortable></Column>
                        <Column field="debt_author" header="Autor" style="display: none;"></Column>
                        <Column field="actions" header="Ações">
                            <template #body="slotProps">
                                <div class="card flex justify-content-center">
                                    <ButtonGroup>
                                        <Button class="p-button-sm" label="Cobrar" icon="pi pi-bell"
                                            @click="charge(slotProps.data)"
                                            :disabled="slotProps.data.debt_status !== 'Pendente'" />
                                        <Button class="p-button-sm" label="Gerar QR" icon="pi pi-qrcode"
                                            @click="qrDialog(slotProps.data)"
                                            :disabled="slotProps.data.debt_status !== 'Pendente'" />
                                        <Button class="p-button-sm" label="Pago" icon="pi pi-wallet"
                                            @click="paid(slotProps.data)"
                                            :disabled="slotProps.data.debt_status !== 'Pendente'" />
                                    </ButtonGroup>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
        </TabView>
    </div>
    <AdminChargeDialog ref="AdminChargeDialog" v-model="chargeDialogVisible" :row="row" />
    <AdminPaidDialog v-model="paidDialogVisible" :row="row" :success="getDebtData" />
    <AdminQrDialog v-model="qrDialogVisible" :row="row" />

    <ConfirmPopup group="headless">
        <template #container="{ message, acceptCallback, rejectCallback }">
            <div class="border-round p-3 m-3" style="padding:15px">
                <h4>{{ message.message }}</h4>
                <div class="flex align-items-center gap-2 mt-3">
                    <Button label="Enviar" @click="acceptCallback" icon="pi pi-check" severity="danger"
                        size="small"></Button>
                    <Button label="Cancelar" outlined @click="rejectCallback" icon="pi pi-times" severity="secondary"
                        size="small" text></Button>
                </div>
            </div>
        </template>
    </ConfirmPopup>
</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import BackButton from '@/components/BackButton.vue'
import AdminQrDialog from '@/components/AdminQrDialog.vue'
import AdminChargeDialog from '@/components/AdminChargeDialog.vue'
import AdminPaidDialog from '@/components/AdminPaidDialog.vue'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ButtonGroup from 'primevue/buttongroup'
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog'
import ValidationError from '@/components/ValidationError.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength, helpers } from '@vuelidate/validators'
import { ref } from 'vue'
import ConfirmPopup from 'primevue/confirmpopup';


export default {
    name: 'DividaEdit',
    components: {
        Navbar,
        InputText,
        Checkbox,
        Button,
        InputNumber,
        Dropdown,
        Calendar,
        MultiSelect,
        TabPanel,
        TabView,
        DataTable,
        Column,
        ButtonGroup,
        Dialog,
        ConfirmDialog,
        BackButton,
        AdminQrDialog,
        AdminChargeDialog,
        AdminPaidDialog,
        ValidationError,
        ConfirmPopup
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            desc: null,
            amount: null,
            selected_recurrence: null,
            recurrences: [
                { name: 'Única', code: 'once' },
                { name: 'Mensal', code: 'recurrent' },
                { name: 'Parcelado', code: 'installment' },
            ],
            emission: null,
            status: null,
            due_day: null,
            due_date: null,
            show_due_alert: false,
            installment_count: null,
            // fee: null,
            selected_client: null,
            selected_tag: [],
            tags: [
                { name: 'Pessoal' },
                { name: 'Empréstimo' },
                { name: 'NodeLake' }
            ],
            row: null,

            instances: [],
            debt_id: null,

            chargeDialogVisible: false,
            paidDialogVisible: false,
            qrDialogVisible: false,

            btn_disabled: false

        }
    },

    validations() {
        return {
            desc: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(5)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(256)
                )
            },
            amount: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(5000)
                )
            },
            selected_recurrence: {
                required: helpers.withMessage('Campo obrigatório', required)
            },
            installment_count: {
                required: helpers.withMessage('Campo obrigatório', requiredIf(function () {
                    return this.selected_recurrence === 'installment'
                })),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(30)
                )
            },
            emission: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
            due_day: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(31)
                )
            },
            due_date: {
                required: helpers.withMessage('Campo obrigatório', requiredIf(function () {
                    return this.selected_recurrence === 'once'
                })),
            },
            selected_tag: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
            selected_client: {
                required: helpers.withMessage('Campo obrigatório', required),
            },

        }
    },

    methods: {
        async getDebtData() {
            const id = this.$route.params.id;
            try {
                const response = await http.get(`/debt/${id}`);
                const debt = response.data;

                const formattedEmission = new Date(debt.start_at).toLocaleDateString('pt-br', { timeZone: 'UTC' });
                const tagsArray = debt.tags.split(',').map(tag => tag.trim());

                this.debt_id = debt.id;
                this.desc = debt.description;
                this.amount = debt.amount;
                this.status = this.formatStatusType(debt.status);
                this.selected_recurrence = debt.category_type;
                this.installment_count = debt.installment_count;
                this.selected_tag = tagsArray;
                this.emission = formattedEmission;
                this.due_day = debt.due_day;
                this.selected_client = debt.user.name;

                try {
                    this.instances = debt.instances.map(instance => {
                        const formatted_due = new Date(instance.due_at).toLocaleDateString('pt-br', { timeZone: 'UTC' });

                        return {
                            debt_id: instance.id,
                            debt_invoice: instance.public_id,
                            debt_desc: debt.description,
                            debt_amount: instance.amount,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(instance.status),
                            debt_author: debt.user.name
                        };
                    });
                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar dados das faturas', 'error');

                }
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar dados da dívida', 'error');
            }
        },

        async UpdateDebt() {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return
            this.btn_disabled = true;

            try {
                const id = this.$route.params.id;
                const selectedTags = this.formatTags(this.selected_tag);
                const formatted_due = this.due_date ? new Date(this.due_date).toISOString().split('T')[0] : null;

                const payload = {
                    description: this.desc,
                    amount: this.amount,
                    installment_count: this.installment_count,
                    tags: selectedTags,
                    due_day: this.due_day,
                    new_due_at: formatted_due,
                    interest_rate: this.interest_rate
                };
                await http.put(`/debt/${id}`, payload);

                this.$router.push('/adm-dividas');
                this.$root.toast('Sucesso', 'Dados da dívida atualizados com sucesso', 'success');

            } catch (error) {
                if (error.response?.data?.detail === 'New_due_at cannot be changed because the debt status is not pending') {
                    this.$root.toast('Ops...', 'Você não pode alterar o vencimento de uma dívida paga', 'error');
                    this.btn_disabled = false
                }else{
                    this.$root.toast('Ops...', 'Não foi possível atualizar a dívida', 'error');
                }
            }
        },

        ConfirmDebtDisable() {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'headless',
                message: 'Deseja realmente desativar essa dívida?',
                accept: () => {
                    this.DebtDisable()
                },
            });
        },

        async DebtDisable() {
            try {
                const id = this.$route.params.id;
                await http.put(`/debt/${id}/disable`);

                this.$router.push('/adm-dividas');
                this.$root.toast('Sucesso', 'Dívida desativada com sucesso', 'success');

            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível desativar a dívida', 'error');
            }
        },

        formatCurrency(amount) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
            return formatter.format(amount);
        },

        formatTags(selectedTags) {
            if (!selectedTags || selectedTags.length === 0) return '';
            return selectedTags.map(tag => tag).join(', ');
        },

        formatStatusType(statusType) {
            switch (statusType) {
                case 'pending':
                    return 'Pendente';
                case 'paid':
                    return 'Pago';
                case 'cancelled':
                    return 'Cancelado';
            }
        },

        async charge(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.chargeDialogVisible = true;
            this.row = row;

        },

        async paid(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.paidDialogVisible = true;
            this.row = row;
        },

        async qrDialog(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.qrDialogVisible = true;
            this.row = row;
        },

        pixCopyAndPaste(text) {
            var text_area = document.createElement("textarea");

            text_area.style.position = 'fixed';
            text_area.style.top = 0;
            text_area.style.left = 0;
            text_area.style.width = '2em';
            text_area.style.height = '2em';
            text_area.style.padding = 0;
            text_area.style.border = 'none';
            text_area.style.outline = 'none';
            text_area.style.boxShadow = 'none';
            text_area.style.background = 'transparent';

            text_area.value = text;

            document.body.appendChild(text_area);
            text_area.focus();
            text_area.select();

            try {
                var successful = document.execCommand('copy');
                this.$root.toast('Confirmação', 'Pix Copia e Cola copiado para sua área de transferência', 'success');

            } catch (err) {
                this.$root.toast('Ops...', 'Não foi possível copiar o Código Pix, tente novamente', 'error');
            }

            document.body.removeChild(text_area);
        },

        async sendEmail(invoice_list) {
            try {
                const payload = {
                    debt_instances_ids: invoice_list
                };
                const response = await http.post('debt/instances/send_reminder', payload);

                const itemCount = Array.isArray(invoice_list) ? invoice_list.length : 1;

                if (response.status === 201) {
                    const successMessage = itemCount === 1 ? 'E-mail enviado com sucesso' : 'E-mails enviados com sucesso';
                    this.$root.toast('Sucesso', successMessage, 'success');

                } else {
                    const errorMessage = itemCount === 1 ? 'Houve um problema ao enviar o e-mail' : 'Houve um problema ao enviar os e-mails';
                    this.$root.toast('Ops...', errorMessage, 'error');
                }

            } catch (error) {
                this.$root.toast('Ops...', 'Algo deu errado ao enviar notificações', 'error');
            }
        },

        checkDueAlert() {
            if (this.selected_recurrence != 'once') {
                this.show_due_alert = true;
            } else {
                this.show_due_alert = false;
            }
        },
    },

    watch: {
        due_day: function () {
            this.checkDueAlert();
        }
    },

    mounted() {
        this.getDebtData();
    }
}

</script>

<style scoped>
#tabview {
    width: 80%;
}

#content {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 40px
}

.header .pi {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    margin-left: 10%;
}

.header h3 {
    flex: 1;
    text-align: center;
}

#form-container {
    width: 100%;
    margin-top: 20px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%
}

label {
    margin-right: 10%;
}

input,
select,
.p-inputtext,
.p-dropdown,
.p-calendar,
.p-multiselect,
.p-inputnumber {
    width: 75%;
    text-align: start;
}

#checkbox {
    display: block;
    text-align: left;
    margin-bottom: 15px;
    margin-top: -10px;
    margin-left: 32%;
}

.form-btn {
    width: 90%;
    margin-bottom: 15px
}

li {
    list-style: none;
}

#copy-paste {
    margin-left: 8px
}

#due-alert {
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 15px;
    color: gray;
    width: fit-content;
    margin-left: 23%;
    padding: 0% 5%;
}


@media only screen and (max-width: 910px) {

    input,
    select,
    .p-inputtext,
    .p-dropdown,
    .p-calendar,
    .p-multiselect,
    .p-inputnumber {
        width: 100%;
        text-align: start;
    }

    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    #checkbox {
        margin-left: 5%;
    }

    label {
        margin-bottom: 5px
    }

    #due-alert {
        margin-left: 0;
    }
}
</style>