import { reactive, readonly } from 'vue';

const user = reactive({
    name: '',
    email: '',
    id: null,
    last_payment: '',
    last_interaction: '',
    is_admin: false
});

const preferences = reactive({
    rows_per_page: ''
});

export async function setUserFromLocalStorage() {
    const userDataString = localStorage.getItem('data');
    if (userDataString) {
        try {
            const userData = JSON.parse(userDataString);
            Object.keys(user).forEach(key => {
                if (userData[key] !== undefined) {
                    user[key] = userData[key];
                }
            });
        } catch (error) {
            this.$root.toast('Ops...', 'Algo deu errado', 'error');
        }
    }
}


export default {
    install(app) {
        app.config.globalProperties.$user = readonly(user);
        setUserFromLocalStorage();
    }
};