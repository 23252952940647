<template>
    <div id="login">
        <div id="form-login">
            <Logo id="title" />

            <div id="inline-message">
                <InlineMessage severity="error" v-show="auth.session_expired">Desconectado por inatividade</InlineMessage>
            </div>

            <form @submit.prevent="login">

                <div class="input-group">
                    <label for="email">E-mail:</label>
                    <InputText v-model="user.username" type="email" id="email" placeholder="exemplo@email.com"
                        autocomplete="username" required />
                </div>

                <div class="input-group">
                    <label for="password">Senha:</label>
                    <InputText v-model="user.password" type="password" id="password" placeholder="********"
                        autocomplete="current-password" required />

                    <Button type="submit" id="login-btn" label="Entrar" severity="info" :loading="loading" />

                    <span id="version">{{ version }}</span>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Logo from '@/components/Logo.vue';
import InlineMessage from 'primevue/inlinemessage';

export default {
    name: 'Login',
    components: {
        InputText,
        Checkbox,
        Button,
        Logo,
        InlineMessage
    },
    data() {
        return {
            remember: false,
            version: '1.0.0'
        }
    },
}
</script>

<script setup>
import http from '@/services/http.js'
import { reactive, ref } from 'vue'
import { useAuth } from '@/stores/auth.js'
import { useRouter } from 'vue-router';
import { useToast } from "primevue/usetoast";

const toast = useToast();
const show = () => {
    toast.add({ severity: 'error', summary: 'Ops...', detail: 'E-mail ou senha incorretos, tente novamente', life: 6000 });
};

const auth = useAuth();
const router = useRouter();

if (auth.isAuthenticated) {
    router.push('/');
}

const user = reactive({
    username: null,
    password: null
});

const loading = ref(false);

async function login() {
    loading.value = true;
    try {
        const formData = new FormData();
        formData.append('username', user.username);
        formData.append('password', user.password);

        const { data } = await http.post('/auth/', formData);

        auth.setToken(data.access_token);

        await auth.checkToken();

        if (auth.data.is_admin) {
            router.push('/dashboard');
        } else {
            router.push('/home');
        }
    } catch (error) {
        loading.value = false;
        show();
    }
}
</script>


<style scoped>
#title {
    margin-bottom: 40px;
    margin-top: 40px;
}

#login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

#form-login {
    position: relative;
    width: 80%;
    max-width: 400px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.input-group {
    display: block;
    margin-bottom: 15px;
}

label {
    margin-right: 20px;
}

input,
.p-password input {
    width: 70%;
}

#checkbox {
    display: block;
    text-align: left;
    margin-top: 15px;
    margin-left: 25%;
}

#login-btn {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 30%;
}

#version {
    position: absolute;
    margin-left: 0;
    margin-right: 0;
    bottom: 5px;
    width: 90%;
    display: flex;
    justify-content: center;
    font-size: small;
}

#inline-message{
    margin-bottom: 20px;
}
</style>
