<template>
    <div class="search-container">
        <IconField iconPosition="left" class="search-field">
            <InputIcon class="pi pi-search"></InputIcon>
            <InputText v-model="query" @input="onInput" :placeholder="placeholder" class="search" />
        </IconField>
        <Button class="search-button" :id="buttonId" :label="buttonLabel" :severity="buttonSeverity" :icon="icon"
            @click="onButtonClick" :disabled="disabled" />
    </div>
</template>

<script>
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';

export default {
    name: 'SearchInput',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Pesquisar'
        },
        buttonId: {
            type: String,
            default: 'charge-all'
        },
        buttonLabel: {
            type: String,
            default: 'Cobrar'
        },
        buttonSeverity: {
            type: String,
            default: 'warning'
        },
        icon: {
            type: String,
            default: 'pi pi-search'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    components: {
        IconField,
        InputIcon,
        InputText,
        Button
    },
    data() {
        return {
            query: this.modelValue
        };
    },
    watch: {
        modelValue(newValue) {
            this.query = newValue;
        }
    },
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.query);
        },
        onButtonClick(event) {
            this.$emit('button-click', event);
        }
    },

};
</script>

<style scoped>
.search-container {
    display: flex;
}

.search-field {
    width: 82%;
}

.search {
    min-width: 100%;
}

.search-button {
    height: 39px;
    margin-left: 10px;
    min-width: 120px;
}

@media only screen and (max-width: 520px) {
    .search-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search-field {
        width: 87%;
    }

    .search {
        min-width: 100%;
    }

    .search-button {
        height: 39px;
        border-radius: 3px;
        margin-top: 10px;
        margin: 10px;
    }
}
</style>
