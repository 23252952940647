<template>
    <ConfirmDialog group="paid" @update:visible="updateVisible">
        <template #message="slotProps">
            <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
                <p>{{ slotProps.message.message }}</p>
                <li> <strong>Invoice:</strong> {{ debt_invoice }}</li>
                <li> <strong>Descrição:</strong> {{ debt_desc }}</li>
                <li> <strong>Valor:</strong> {{ formatCurrency(debt_amount) }}</li>
                <li> <strong>Autor:</strong> {{ debt_name }}</li>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import http from '@/services/http.js';
import { ref, watch } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import { useToast } from "primevue/usetoast";

const toast = useToast();
const confirm = useConfirm();

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    row: {
        type: Object,
        default: null
    },
    success: {
        type: Function,
        required: true
    }
});

const emit = defineEmits(['update:modelValue']);

const debt_invoice = ref(null);
const debt_desc = ref(null);
const debt_amount = ref(null);
const debt_name = ref(null);

const updateVisible = (value) => {
    emit('update:modelValue', value);
};

const closeDialog = () => {
    emit('update:modelValue', false);
};

const paid = (row) => {
    debt_invoice.value = row.debt_invoice;
    debt_desc.value = row.debt_desc;
    debt_amount.value = row.debt_amount;
    debt_name.value = row.debt_author;
    confirm.require({
        group: 'paid',
        header: 'Considerar Pago',
        message: 'Deseja considerar esta dívida como paga?',
        icon: 'pi pi-exclamation-circle',
        acceptIcon: 'pi pi-wallet',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
        acceptClass: 'p-button-warning p-button-sm',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Considerar Pago',
        accept: () => {
            considerPaid(debt_invoice.value, debt_desc);
            closeDialog();
        },
        reject: () => {
            closeDialog();
        }
    });
};
const considerPaid = async (debt_invoice, debt_desc) => {
    try {
        await http.post(`/payment/${debt_invoice}/mark-as-paid`);
        await props.success();
        toast.add({ severity: 'success', summary: 'Confirmação', detail: `Dívida ${debt_desc.value} foi considerada paga`, life: 3000 });

    } catch (error) {
        toast.add({ severity: 'error', summary: 'Ops...', detail: 'Não foi possível considerar a dívida paga', life: 3000 });

    }

};

const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
    return formatter.format(amount);
};

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        paid(props.row);
    }
});

</script>

<style scoped>
button {
    margin-left: 15px;
    height: 40px;
}

li {
    list-style: none;
}
</style>
