import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import http from '@/services/http.js';
import { setUserFromLocalStorage } from '@/services/userMixin';

export const useAuth = defineStore('auth', () => {

    const token = ref(localStorage.getItem("token"));
    const data = ref(JSON.parse(localStorage.getItem("data")));

    function setToken(tokenValue, userData) {
        localStorage.setItem('token', tokenValue);
        localStorage.setItem('data', JSON.stringify(userData));
        token.value = tokenValue;
        data.value = userData;
    }

    const isAuthenticated = computed(() => {
        return token.value;
    })

    async function checkToken() {
        try {
            const tokenAuth = 'Bearer ' + token.value;
            const { data } = await http.get("/user/me", {
                headers: {
                    Authorization: tokenAuth,
                },
            });
            setToken(token.value, data);
            await setUserFromLocalStorage();
            return data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clear();
            }
        }
    }
    

    function clear() {
        localStorage.removeItem('token');
        localStorage.removeItem('data');
        token.value = '';
        data.value = '';
        setUserFromLocalStorage();
    }

    return {
        token,
        setToken,
        checkToken,
        isAuthenticated,
        clear,
        data
    }

})
