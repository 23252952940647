<template>
    <Dialog id="dialog" v-model:visible="modalVisible" modal header="Atualizar senha" :style="{ width: '25rem' }">
        <section>
            <div class="form-row">
                <label for="password">Senha:</label>
                <Password id="password" v-model="password" :feedback="false" />
            </div>
            <ValidationError v-if="v$.password.$error" :errors="v$.password.$errors" />

            <div class="form-row">
                <label for="password_confirm">Confir. Senha:</label>
                <Password id="password_confirm" v-model="password_confirmation" :feedback="false" />
            </div>
            <ValidationError v-if="v$.password_confirmation.$error" :errors="v$.password_confirmation.$errors" />

            <div class="form-footer">
                <Button type="button" label="Salvar" severity="danger" @click="editPassword"></Button>
            </div>
        </section>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import FloatLabel from 'primevue/floatlabel';
import Password from 'primevue/password';
import Button from 'primevue/button';
import http from '@/services/http.js'
import ValidationError from '@/components/ValidationError.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'

export default {
    name: 'ResetPasswordDialog',
    props: {
        prop_id: Number
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            modalVisible: false,
            password: null,
            password_confirmation: null
        };
    },
    validations() {
        return {
            password: {
                minLength: helpers.withMessage(
                    ({ $params }) => `Precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(30)
                )
            },
            password_confirmation: {
                sameAsPassword: helpers.withMessage('Senhas não são iguais', sameAs(this.password)),
                minLength: helpers.withMessage(
                    ({ $params }) => `Precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(30)
                ),

            }
        }
    },
    components: {
        Dialog,
        Password,
        Button,
        FloatLabel,
        ValidationError
    },
    methods: {
        openDialog() {
            this.modalVisible = true;
        },
        async editPassword() {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return
            if (this.password == this.password_confirmation) {
                try {
                    const payload = {
                        new_password: this.password
                    };
                    await http.post(`/user/change_password/${this.prop_id}`, payload);

                    this.modalVisible = false;
                    this.$root.toast('Sucesso', 'senha alterada com sucesso', 'success');
                    this.password = null;
                    this.password_confirmation = null;

                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível alterar a senha', 'error');
                }
            } else {
                this.$root.toast('Ops...', 'As senhas precisam ser iguais', 'error');
            }
        }
    },
}
</script>

<style scoped>
.form-row {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

.form-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.form-row label {
    font-weight: 400;
    width: 25%;
}
</style>