<template>
    <Navbar />
    <div id="content">
        <div id="form-container">
            <div class="header">
                <BackButton />
                <h3>Nova Dívida</h3>
            </div>
            <form @submit.prevent="CreateDebt">
                <div class="input-group">
                    <label for="desc">Descrição:</label>
                    <InputText v-model="desc" type="text" id="desc" placeholder="VPS 6GB"/>
                </div>
                <ValidationError v-if="v$.desc.$error" :errors="v$.desc.$errors" />


                <div class="input-group">
                    <label for="amount">Valor:</label>
                    <InputNumber v-model="amount" inputId="minmaxfraction" :minFractionDigits="2" :maxFractionDigits="2"
                        placeholder="120,00" />
                </div>
                <ValidationError v-if="v$.amount.$error" :errors="v$.amount.$errors" />


                <div class="input-group">
                    <label for="recurrence">Recorrência:</label>
                    <Dropdown v-model="selected_recurrence" :options="recurrences" optionLabel="name" optionValue="code"
                        placeholder="Selecione a Recorrência" class="w-full md:w-14rem" />
                </div>
                <ValidationError v-if="v$.selected_recurrence.$error" :errors="v$.selected_recurrence.$errors" />


                <div class="input-group" v-show="selected_recurrence == 'installment'">
                    <label for="instance">Parcelas:</label>
                    <InputNumber v-model="instance" mode="decimal" showButtons :min="1" :max="24" placeholder="1 a 24" />
                </div>
                <ValidationError v-if="selected_recurrence == 'installment' && v$.instance.$error" :errors="v$.instance.$errors" />


                <div class="input-group">
                    <label for="emission">Emissão:</label>
                    <Calendar v-model="emission" placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy"/>
                </div>
                <ValidationError v-if="v$.emission.$error" :errors="v$.emission.$errors" />

                <div class="input-group">
                    <label for="due">Vencimento:</label>
                    <InputNumber v-model="due_day" mode="decimal" showButtons :min="1" :max="31" placeholder="1 a 31"/>
                </div>
                <ValidationError v-if="v$.due_day.$error" :errors="v$.due_day.$errors" />

                <div v-if="show_due_alert" id="due-alert">
                    <span class="p-error">O dia de vencimento já passou, próximas faturas serão emitidas no mês
                        seguinte</span>
                </div>

                <!-- <div class="input-group">
                <label for="fee">Juros:</label>
                <InputNumber v-model="fee" inputId="minmaxfraction" :minFractionDigits="2" :maxFractionDigits="2" required/>
              </div> -->

                <div class="input-group">
                    <label for="recurrence">Tags:</label>
                    <MultiSelect v-model="selected_tag" display="chip" :options="tags" optionLabel="name"
                        placeholder="Selecione a Categoria" class="w-full md:w-20rem"/>
                </div>
                <ValidationError v-if="v$.selected_tag.$error" :errors="v$.selected_tag.$errors" />


                <div class="input-group">
                    <label for="client">Cliente:</label>
                    <Dropdown v-model="selected_client" :options="clients" filter optionLabel="name"
                        optionValue="user_id" placeholder="Selecione o Cliente" class="w-full md:w-14rem" />
                </div>
                <ValidationError v-if="v$.selected_client.$error" :errors="v$.selected_client.$errors" />


                <Button type="submit" id="create-btn" :label="btn_value" severity="info" :disabled="loading" />
            </form>
        </div>
    </div>
</template>


<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import BackButton from '@/components/BackButton.vue'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import ValidationError from '@/components/ValidationError.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf, minLength, maxLength, helpers } from '@vuelidate/validators'
import { ref } from 'vue'


export default {
    name: 'Login',
    components: {
        Navbar,
        InputText,
        Checkbox,
        Button,
        InputNumber,
        Dropdown,
        Calendar,
        MultiSelect,
        BackButton,
        ValidationError
    },
    data() {
        return {
            desc: null,
            amount: null,
            selected_recurrence: null,
            recurrences: [
                { name: 'Única', code: 'once' },
                { name: 'Mensal', code: 'recurrent' },
                { name: 'Parcelado', code: 'installment' },
            ],
            emission: null,
            due_day: null,
            instance: null,
            // fee: null,
            selected_client: null,
            clients: [],
            selected_tag: null,
            tags: [
                { name: 'Pessoal' },
                { name: 'Empréstimo' },
                { name: 'NodeLake' }
            ],

            today_day: null,
            today_month: null,
            today_year: null,
            show_due_alert: false,

            btn_value: 'Cadastrar',
            loading: false
        }
    },

    validations() {
        return {
            desc: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(5)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(256)
                )
            },
            amount: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(5000)
                )
            },
            selected_recurrence: {
                required: helpers.withMessage('Campo obrigatório', required)
            },
            instance: {
                required: helpers.withMessage('Campo obrigatório', requiredIf(function(){
                    return this.selected_recurrence === 'installment'
                })),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(24)
                )
            },
            emission: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
            due_day: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(1)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(31)
                )
            },
            selected_tag: {
                required: helpers.withMessage('Campo obrigatório', required),
            },
            selected_client: {
                required: helpers.withMessage('Campo obrigatório', required),
            },

        }
    },

    watch: {
        due_day: function () {
            this.checkDueAlert();
        },
        emission: function () {
            this.checkDueAlert();
        },
        loading: function () {
            this.buttonLabel();
        }
    },
    setup() {
        const v$ = useVuelidate();
        const selected_tag = ref([]);
        const tags = [
            { name: 'Pessoal' },
            { name: 'Empréstimo' },
            { name: 'NodeLake' }
        ];

        const formatTags = (selectedTags) => {
            if (!selectedTags || selectedTags.length === 0) return '';
            return selectedTags.map(tag => tag.name).join(', ');
        };
        return {
            selected_tag,
            tags,
            formatTags,
            v$
        };
    },
    methods: {
        async CreateDebt() {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return
            try {
                this.loading = true;
                const formattedEmission = this.emission.toISOString().split('T')[0];
                const payload = {
                    description: this.desc,
                    amount: this.amount,
                    category_type: this.selected_recurrence,
                    installment_count: this.instance,
                    tags: this.formatTags(this.selected_tag),
                    start_at: formattedEmission,
                    due_day: this.due_day,
                    user_id: this.selected_client,
                };
                await http.post('/debt/', payload);

                this.$router.push('/adm-dividas');
                this.$root.toast('Sucesso', 'Dívida criada com sucesso', 'success');

            } catch (error) {
                if (error.response.data.detail[0]?.msg === 'Input should be less than or equal to 24') {
                    this.$root.toast('Ops...', 'Parcelas devem ser menor ou igual a 24', 'error');
                } else {
                    this.$root.toast('Ops...', 'Algo deu errado ao cadastrar a dívida', 'error');
                }
                this.loading = false;
            }
        },

        async getUsers() {
            try {
                const response = await http.get('/user/');
                this.clients = response.data.map(user => ({
                    user_id: user.id,
                    name: user.name
                }));
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar clientes', 'error');

            }
        },

        checkDueAlert() {
            const today = new Date();
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.today_day = today.getDate();
            this.today_month = today.getMonth() + 1;
            this.today_year = today.getFullYear();

            if (this.emission.getMonth() + 1 == this.today_month && this.due_day <= this.today_day && this.due_day != null) {
                this.show_due_alert = true;
            } else {
                this.show_due_alert = false;
            }
        },

        buttonLabel() {
            if (this.loading == true) {
                this.btn_value = "Carregando..."
            } else {
                this.btn_value = "Cadastrar"
            }
        }
    },
    mounted() {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.emission = tomorrow;
        this.getUsers();
    }
}

</script>

<style scoped>
#content {
    display: flex;
    justify-content: center;
}

#form-container {
    width: 80%;
    margin-top: 50px;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.header h3 {
    flex: 1;
    text-align: center;
}

.input-group {
    margin-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

label {
    margin-right: 10%;
}

input,
select,
.p-inputtext,
.p-dropdown,
.p-calendar,
.p-multiselect,
.p-inputnumber {
    width: 75%;
    text-align: start;
}

#create-btn {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 90%;
}

#due-alert {
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 15px;
    color: gray;
    width: fit-content;
    margin-left: 27%;
    padding-left: 5%;
    padding-right: 5%;
}

@media only screen and (max-width: 910px) {

    input,
    select,
    .p-inputtext,
    .p-dropdown,
    .p-calendar,
    .p-multiselect,
    .p-inputnumber {
        width: 100%;
        text-align: start;
    }

    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    label {
        margin-bottom: 5px
    }

    #due-alert {
        margin-left: 0;
    }
}
</style>