import axios from 'axios';
import { useAuth } from '@/stores/auth.js';

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_ROOT_API,
});

axiosInstance.interceptors.request.use(config => {
	const auth = useAuth();
	const headers = {
		// TODO: tirar isso aqui na production 
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
		'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
		'Access-Control-Allow-Credentials': true,
	};

	if (auth.isAuthenticated) {
		headers.Authorization = `Bearer ${auth.token}`;
	}

	config.headers = headers;
	return config;
	
});

axiosInstance.interceptors.response.use(response => {
	return response;
}, error => {
	const auth = useAuth();
	if (error.response && error.response.status === 401 && this.$router.currentRoute.value.path !== '/login') {
		auth.clear();
		auth.session_expired = true;
	}
	return Promise.reject(error);
});

export default axiosInstance;