<template>
    <ConfirmDialog group="charge" @update:visible="updateVisible">
        <template #message="slotProps">
            <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
                <p>{{ slotProps.message.message }}</p>
                <li> <strong>Invoice:</strong> {{ debt_invoice }}</li>
                <li> <strong>Descrição:</strong> {{ debt_desc }}</li>
                <li> <strong>Valor:</strong> {{ formatCurrency(debt_amount) }}</li>
                <li> <strong>Autor:</strong> {{ debt_name }}</li>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import http from '@/services/http.js';
import { ref, watch } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import { useToast } from "primevue/usetoast";

const toast = useToast();
const confirm = useConfirm();

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    row: {
        type: Object,
        default: null
    }
});

const emit = defineEmits(['update:modelValue']);

const debt_invoice = ref(null);
const debt_desc = ref(null);
const debt_amount = ref(null);
const debt_name = ref(null);

const updateVisible = (value) => {
    emit('update:modelValue', value);
};

const closeDialog = () => {
    emit('update:modelValue', false);
};

const charge = (row) => {
    debt_invoice.value = row.debt_invoice;
    debt_desc.value = row.debt_desc;
    debt_amount.value = row.debt_amount;
    debt_name.value = row.debt_author;
    confirm.require({
        group: 'charge',
        header: 'Enviar Cobrança',
        message: 'Deseja enviar uma cobrança via E-mail para o cliente?',
        icon: 'pi pi-exclamation-circle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectClass: 'p-button-outlined p-button-sm p-button-secondary',
        acceptClass: 'p-button-sm',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Enviar',
        accept: () => {
            sendEmail([debt_invoice.value]);
            closeDialog();
        },
        reject: () => {
            closeDialog();
        }
    });
};

const sendEmail = async (invoiceList) => {
    try {
        const payload = {
            public_ids: invoiceList
        };
        const response = await http.post('debt/instances/send_reminder', payload);

        const itemCount = Array.isArray(invoiceList) ? invoiceList.length : 1;

        if (response.detail === 'Successfully sent invoice reminder') {
            const successMessage = itemCount === 1 ? 'E-mail enviado com sucesso' : 'E-mails enviados com sucesso';
            toast.add({ severity: 'success', summary: 'Confirmação', detail: `${successMessage}`, life: 3000 });
        } else {
            const errorMessage = itemCount === 1 ? 'O e-mail falhou ao ser enviado' : 'Alguns e-mails falharam ao serem enviados';
            toast.add({ severity: 'warning', summary: 'Ops...', detail: `${errorMessage}`, life: 3000 });
        }

    } catch (error) {
        toast.add({ severity: 'error', summary: 'Ops...', detail: 'Algo deu errado ao enviar notificações', life: 3000 });
    }
};

const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
    return formatter.format(amount);
};

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        charge(props.row);
    } else {

    }
});

defineExpose({
    sendEmail
});
</script>

<style scoped>
button {
    margin-left: 15px;
    height: 40px;
}

li {
    list-style: none;
}
</style>