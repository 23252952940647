<template>
    <Navbar />
    <div id="content">
        <div id="welcome">
            <div id="welcome-title">
                <h3>Olá, {{ $user.name }}!</h3>
            </div>
            <div id="month-select">
                <Calendar @date-select="getReports" v-model="selected_date" view="month" dateFormat="mm/yy"
                    placeholder="Selecione o mês" showIcon :showOnFocus="false" />
            </div>
        </div>

        <CardList :cards="cardData" />

        <TabView id="tabview">
            <TabPanel>
                <template #header>
                    <div class="p-overlay-badge">
                        <span>Dívidas do Mês</span>
                    </div>
                </template>
                <div class="card">
                    <DataTable v-show="!loading" :value="filtered_faturas" :rows="pagination['adm_debts']" paginator
                        :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem"
                        @update:rows="updatePagination('adm_debts', $event)">

                        <template #header>
                            <SearchInput v-model="search" @button-click="confirmChargeAll($event, filtered_faturas)"
                                :buttonId="'charge-all'" :buttonLabel="'Cobrar tudo'" :buttonSeverity="'warning'"
                                :icon="'pi pi-bell'" />
                        </template>
                        <Column field="debt_id" header="ID" sortable></Column>
                        <Column field="debt_invoice" header="Invoice" sortable style="display: none;"></Column>
                        <Column field="debt_desc" header="Descrição" sortable></Column>
                        <Column field="debt_emission" header="Emissão" sortable></Column>
                        <Column field="debt_author" header="Autor" sortable></Column>
                        <Column field="debt_due" header="Vencimento" sortable></Column>
                        <Column field="debt_amount" header="Valor" sortable>
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.debt_amount) }}
                            </template>
                        </Column>
                        <Column field="debt_status" header="Estado" sortable></Column>
                        <Column field="debt_actions" header="Ações">
                            <template #body="slotProps">
                                <div class="btn-group card flex justify-content-center">
                                    <ButtonGroup>
                                        <Button class="p-button-sm" label="Cobrar" icon="pi pi-bell"
                                            @click="charge(slotProps.data)" />
                                        <Button class="p-button-sm" label="Gerar QR" icon="pi pi-qrcode"
                                            @click="qrDialog(slotProps.data)" />
                                        <Button class="p-button-sm" label="Pago" icon="pi pi-wallet"
                                            @click="paid(slotProps.data)" />
                                    </ButtonGroup>

                                </div>
                            </template>
                        </Column>
                    </DataTable>
                    <div v-show="loading">
                        <DataTable :value="skeleton">
                            <Column field="debt_id" header="ID">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_desc" header="Descrição">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_emission" header="Emissão">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_author" header="Autor">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_due" header="Vencimento">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_amount" header="Valor">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_status" header="Estado">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                            <Column field="debt_actions" header="Ações">
                                <template #body>
                                    <Skeleton></Skeleton>
                                </template>
                            </Column>
                        </DataTable>
                        <div v-show="is_debt_empty">
                            <h4>Nenhuma pendência, incrível!</h4>
                        </div>
                    </div>
                </div>
            </TabPanel>

            <TabPanel>
                <template #header>
                    <div class="p-overlay-badge">
                        <span style="padding-right: 10px;">Dívidas Atrasadas</span>
                        <DueBadge :count="filtered_faturas_atrasadas.length" v-show="is_debt_overdue" />
                    </div>
                </template>
                <div class="card" v-show="is_debt_overdue">
                    <DataTable :value="filtered_faturas_atrasadas" :rows="pagination['adm_debts_overdue']" paginator
                        :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem"
                        @update:rows="updatePagination('adm_debts_overdue', $event)">

                        <template #header>
                            <SearchInput v-model="search_overdue"
                                @button-click="confirmChargeAll($event, filtered_faturas_atrasadas)"
                                :buttonId="'charge-all-due'" :buttonLabel="'Cobrar tudo'" :buttonSeverity="'warning'"
                                :icon="'pi pi-bell'" />
                        </template>
                        <Column field="debt_id" header="ID" sortable></Column>
                        <Column field="debt_invoice" header="Invoice" sortable style="display: none;"></Column>
                        <Column field="debt_desc" header="Descrição" sortable></Column>
                        <Column field="debt_emission" header="Emissão" sortable></Column>
                        <Column field="debt_author" header="Autor" sortable></Column>
                        <Column field="debt_due" header="Vencimento" sortable></Column>
                        <Column field="debt_amount" header="Valor" sortable>
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.debt_amount) }}
                            </template>
                        </Column>
                        <Column field="debt_status" header="Estado" sortable></Column>
                        <Column field="debt_actions" header="Ações">
                            <template #body="slotProps">
                                <div class="btn-group card flex justify-content-center">
                                    <ButtonGroup>
                                        <Button class="p-button-sm" label="Cobrar" icon="pi pi-bell"
                                            @click="charge(slotProps.data)" />
                                        <Button class="p-button-sm" label="Gerar QR" icon="pi pi-qrcode"
                                            @click="qrDialog(slotProps.data)" />
                                        <Button class="p-button-sm" label="Pago" icon="pi pi-wallet"
                                            @click="paid(slotProps.data)" />
                                    </ButtonGroup>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <div v-show="!is_debt_overdue">
                    <h4>Nenhuma dívida atrasada, surpreendente!</h4>
                </div>
            </TabPanel>
        </TabView>

        <AdminChargeDialog ref="AdminChargeDialog" v-model="chargeDialogVisible" :row="row" />
        <AdminPaidDialog v-model="paidDialogVisible" :row="row" :success="getReports" />
        <AdminQrDialog v-model="qrDialogVisible" :row="row" />

        <ConfirmPopup group="headless">
            <template #container="{ message, acceptCallback, rejectCallback }">
                <div class="border-round p-3 m-3" style="padding:15px">
                    <h4>{{ message.message }}</h4>
                    <div class="flex align-items-center gap-2 mt-3">
                        <Button label="Enviar" @click="acceptCallback" icon="pi pi-check" severity="warning"
                            size="small"></Button>
                        <Button label="Cancelar" outlined @click="rejectCallback" icon="pi pi-times"
                            severity="secondary" size="small" text></Button>
                    </div>
                </div>
            </template>
        </ConfirmPopup>
    </div>
</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import AdminQrDialog from '@/components/AdminQrDialog.vue'
import AdminChargeDialog from '@/components/AdminChargeDialog.vue'
import AdminPaidDialog from '@/components/AdminPaidDialog.vue'
import DueBadge from '@/components/DueBadge.vue'
import SearchInput from '@/components/SearchInput.vue'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import ButtonGroup from 'primevue/buttongroup';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import ConfirmPopup from 'primevue/confirmpopup'
import Skeleton from 'primevue/skeleton';
import CardList from '@/components/CardList.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import { getPagination, setPagination } from '@/services/pagination.js';

let invoiceList = [];

export default {
    name: 'Home',
    components: {
        Navbar,
        Dropdown,
        DataTable,
        Column,
        Button,
        Calendar,
        ButtonGroup,
        ConfirmDialog,
        Dialog,
        InputIcon,
        IconField,
        InputText,
        ConfirmPopup,
        Skeleton,
        AdminQrDialog,
        AdminChargeDialog,
        AdminPaidDialog,
        CardList,
        TabView,
        TabPanel,
        DueBadge,
        InputGroupAddon,
        InputGroup,
        SearchInput
    },
    data() {
        return {
            received_amount: null,
            receivable_amount_all: null,
            receivable_amount: null,
            selected_date: null,

            faturas: [],
            filtered_faturas: [],
            search: '',

            faturas_atrasadas: [],
            filtered_faturas_atrasadas: [],
            search_overdue: '',

            skeleton: Array.from({ length: 5 }, () => ({})),
            loading: true,
            row: null,
            is_debt_empty: false,
            is_debt_overdue: false,

            chargeDialogVisible: false,
            paidDialogVisible: false,
            qrDialogVisible: false,

            pagination: {
                adm_debts: getPagination('adm_debts'),
                adm_debts_overdue: getPagination('adm_debts_overdue')
            }
        };
    },
    computed: {
        cardData() {
            return [
                {
                    title: 'Valor Devido',
                    value: this.receivable_amount,
                },
                {
                    title: 'Valor Pago',
                    value: this.received_amount
                },
                {
                    title: 'Dívida Geral',
                    value: this.receivable_amount_all
                }
            ];
        }
    },
    methods: {
        async getReports() {
            if (this.selected_date instanceof Date) {
                const month = String(this.selected_date.getMonth() + 1).padStart(2, '0');
                const year = String(this.selected_date.getFullYear())
                const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });

                try {
                    const response = await http.get(`/report/admin/resume?date_year=${year}&date_month=${month}`);
                    this.received_amount = formatter.format(response.data.received_amount);
                    this.receivable_amount_all = formatter.format(response.data.receivable_amount_all);
                    this.receivable_amount = formatter.format(response.data.receivable_amount);

                    await this.getDebts();
                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar o Report', 'error');
                }
            } else {
                this.$root.toast('Ops...', 'Algo deu errado, tente novamente mais tarde', 'error');
            }
        },

        async getDebts() {
            if (this.selected_date instanceof Date) {
                const month = String(this.selected_date.getMonth() + 1).padStart(2, '0');
                const year = String(this.selected_date.getFullYear())

                try {
                    const response = await http.get(`/debt/instances/?date_year=${year}&date_month=${month}&status=pending`);
                    this.faturas = response.data.map(fatura => {

                        const formatted_start = new Date(fatura.debt.start_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });
                        const formatted_due = new Date(fatura.due_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });

                        return {
                            debt_id: fatura.id,
                            debt_invoice: fatura.public_id,
                            debt_desc: fatura.debt.description,
                            debt_emission: formatted_start,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(fatura.status),
                            debt_amount: fatura.amount,
                            debt_author: fatura.debt.user.name
                        };
                    });
                    this.loading = false;
                    this.filtered_faturas = this.faturas;

                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar as dívidas', 'error');
                }

                try {
                    const response = await http.get(`/debt/instances/?overdue=true&status=pending`);
                    this.faturas_atrasadas = response.data.map(fatura_atrasada => {

                        const formatted_start = new Date(fatura_atrasada.debt.start_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });
                        const formatted_due = new Date(fatura_atrasada.due_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });

                        return {
                            debt_id: fatura_atrasada.id,
                            debt_invoice: fatura_atrasada.public_id,
                            debt_desc: fatura_atrasada.debt.description,
                            debt_emission: formatted_start,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(fatura_atrasada.status),
                            debt_amount: fatura_atrasada.amount,
                            debt_author: fatura_atrasada.debt.user.name
                        };
                    });
                    this.is_debt_overdue = this.faturas_atrasadas.length > 0;
                    this.loading = false;
                    this.filtered_faturas_atrasadas = this.faturas_atrasadas;

                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar as dívidas atrasadas', 'error');
                }
            }
        },

        formatStatusType(statusType) {
            switch (statusType) {
                case 'pending':
                    return 'Pendente';
                case 'paid':
                    return 'Pago';
                case 'cancelled':
                    return 'Cancelado';
            }
        },

        async chargeAll(debts) {
            try {
                invoiceList = [];
                invoiceList = debts.map(fatura => fatura.debt_invoice);
                this.$refs.AdminChargeDialog.sendEmail(invoiceList);
            } catch (error) {
                this.$root.toast('Ops...', 'Algo deu errado ao cobrar todas as dívidas', 'error');
            }
        },

        confirmChargeAll(event, debts) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'headless',
                message: 'Deseja realmente cobrar todas as dívidas da lista?',
                accept: () => {
                    this.chargeAll(debts);
                },
            });
        },

        async qrDialog(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.qrDialogVisible = true;
            this.row = row;
        },

        async charge(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.chargeDialogVisible = true;
            this.row = row;

        },

        async paid(row) {
            if (!row.debt_invoice) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente', 'error');
                return;
            }

            this.paidDialogVisible = true;
            this.row = row;
        },

        searchData() {
            if (this.search) {
                this.filtered_faturas = this.faturas.filter(fatura =>
                    Object.values(fatura).some(value =>
                        value.toString().toLowerCase().includes(this.search.toLowerCase())
                    )
                );
            } else {
                this.filtered_faturas = this.faturas;
            }
        },

        searchOverdueData() {
            if (this.search_overdue) {
                this.filtered_faturas_atrasadas = this.faturas_atrasadas.filter(fatura_atrasada =>
                    Object.values(fatura_atrasada).some(value =>
                        value.toString().toLowerCase().includes(this.search_overdue.toLowerCase())
                    )
                );
            } else {
                this.filtered_faturas_atrasadas = this.faturas_atrasadas;
            }
        },

        formatCurrency(amount) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
            return formatter.format(amount);
        },

        updatePagination(key, value) {
            setPagination(key, value);
            this.pagination[key] = value;
        }
    },
    mounted() {
        const currentDate = new Date();
        this.selected_date = currentDate;
        this.getReports();
    },

    watch: {
        search(newValue) {
            this.searchData();
        },
        search_overdue(newValue) {
            this.searchOverdueData();
        }
    }
}
</script>

<style scoped>
#content {
    padding: 40px 5%;
}

#welcome {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}

#welcome-title {
    flex: 1;
    text-align: center;
}

#month-select {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    width: 200px;
}

.card-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

h4 {
    margin-top: 15px;
}

.card-item {
    padding: 5px;
    width: 250px;
    height: 115px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.card-item p {
    font-weight: bold;
    margin: 0
}

#fee {
    font-size: x-small;
}

#debt-table {
    margin-top: 30px;
    width: 100%;
}

.search {
    min-width: 100%;
}

#debt-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 15px;
}

#header-title {
    text-align: left;
    align-self: flex-end
}

#header-title h4 {
    margin: 0;
}

#header-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end
}

.btn-group {
    width: 285px;
}

#charge-all {
    height: 39px;
}

.p-dropdown {
    height: 40px;
}

@media only screen and (max-width: 700px) {
    #welcome {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-bottom: 0px;
    }

    #welcome-title {
        flex: 1;
        text-align: center;
    }

    #month-select {
        cursor: pointer;
        font-size: 1.5rem;
        position: relative;
        right: 0;
        width: 200px;
    }

    .card-item {
        width: 115px;
        height: 115px;
    }

    .btn-group {
        width: auto;
    }

    #header-buttons {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-items: flex-end;
        display: flex;
        align-items: flex-end
    }

    #header-title {
        display: flex;
        align-items: flex-end;
    }

    #header-title h4 {
        margin-bottom: 0px;
    }

    button {
        margin-bottom: 10px;
        width: 120px;
    }
}

@media only screen and (max-width: 380px) {
    .card-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }

    .card-item {
        margin-bottom: 15px;
    }
}
</style>
