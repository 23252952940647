<template>
    <Navbar />
    <div id="content">
        <div id="form-container">
            <div class="header">
                <BackButton />
                <h3>Editar Cliente</h3>
            </div>
            <form @submit.prevent="UpdateClient">
                <div class="input-group">
                    <label for="nome">Nome:</label>
                    <InputText v-model="nome" type="text" id="nome" placeholder="Nome Sobrenome" />
                </div>
                <ValidationError v-if="v$.nome.$error" :errors="v$.nome.$errors" />

                <div class="input-group">
                    <label for="email">E-mail:</label>
                    <InputText v-model="email" type="email" id="email" placeholder="exemplo@email.com" />
                </div>
                <ValidationError v-if="v$.email.$error" :errors="v$.email.$errors" />

                <div class="input-group">
                    <label for="last_payment">Último Pgto:</label>
                    <InputText v-model="last_payment" type="date" id="last_payment" placeholder="dd/mm/aaaa" readonly />
                </div>

                <div class="input-group">
                    <label for="last_payment">Último Login:</label>
                    <InputText v-model="last_interaction" type="date" id="last_interaction" placeholder="dd/mm/aaaa"
                        readonly />
                </div>

                <div class="input-group">
                    <label for="status">Estado:</label>
                    <Dropdown v-model="selected_status" :options="status" optionLabel="name" optionValue="value"
                        placeholder="Selecione o Estado" class="w-full md:w-14rem" />
                </div>

                <ResetPasswordDialog :prop_id="id" ref="ResetPasswordDialog" />
                <Button class="buttons" id="password-btn" @click="$refs.ResetPasswordDialog.openDialog()"
                    label="Atualizar Senha" severity="danger" :disabled="btn_disabled"/>
                <Button class="buttons" id="update-btn" @click="UpdateClient()" label="Atualizar" severity="info" :disabled="btn_disabled"/>
            </form>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import BackButton from '@/components/BackButton.vue'
import ValidationError from '@/components/ValidationError.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import http from '@/services/http.js'
import ResetPasswordDialog from '@/components/ResetPasswordDialog.vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength, helpers } from '@vuelidate/validators'

export default {
    name: 'ClienteEdit',
    components: {
        Navbar,
        InputText,
        Button,
        Dropdown,
        ResetPasswordDialog,
        BackButton,
        ValidationError
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            id: null,
            nome: null,
            email: null,
            last_payment: null,
            last_interaction: null,
            selected_status: null,
            btn_disabled: false,
            status: [
                { value: true, name: 'Ativo' },
                { value: false, name: 'Inativo' }
            ]
        }
    },

    validations() {
        return {
            nome: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(5)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(100)
                )
            },
            email: {
                required: helpers.withMessage('Campo obrigatório', required),
                email: helpers.withMessage('Deve ser um E-mail válido. Exemplo: email@exemplo.com', email)
            },
        }
    },
    methods: {
        async getClientData(id) {
            try {
                const response = await http.get(`/user/${id}`);
                const user = response.data;
                const formattedPayment = new Date(user.last_payment).toISOString().split('T')[0];
                const formattedInteraction = new Date(user.last_interaction).toISOString().split('T')[0];

                this.id = user.id
                this.nome = user.name;
                this.email = user.email;
                this.last_payment = formattedPayment
                this.last_interaction = formattedInteraction;
                this.selected_status = user.active;
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar dados do cliente', 'error');
            }
        },

        async UpdateClient() {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return
            this.btn_disabled = true;

            try {
                const id = this.$route.params.id;
                const payload = {
                    name: this.nome,
                    email: this.email,
                    active: this.selected_status
                };
                await http.put(`/user/${id}`, payload);

                this.$root.toast('Sucesso', 'Dados do cliente atualizados com sucesso', 'success');
                this.$router.push('/clientes');

            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível atualizar o cliente', 'error');
            }
        },
    },
    mounted() {
        const id = this.$route.params.id;
        this.getClientData(id);
    }
}
</script>

<style scoped>
#content {
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.header h3 {
    flex: 1;
    text-align: center;
}

#form-container {
    width: 80%;
    margin-top: 50px
}

.input-group {
    margin-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input-group label {
    margin-right: 10%;
}

.input-group input,
.input-group select,
.input-group .p-inputtext,
.input-group .p-dropdown,
.input-group .p-calendar,
.input-group .p-multiselect,
.input-group .p-inputnumber {
    width: 75%;
    text-align: start;
}

.buttons {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 90%;
}

@media only screen and (max-width: 910px) {

    .input-group input,
    .input-group select,
    .input-group .p-inputtext,
    .input-group .p-dropdown,
    .input-group .p-calendar,
    .input-group .p-multiselect,
    .input-group .p-inputnumber {
        width: 100%;
        text-align: start;
    }

    /* #form-container {
        width: 100%;
    } */

    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    label {
        margin-bottom: 5px;
    }
}
</style>
