<template>
    <Navbar />
    <div id="content">
        <div id="form-container">
            <div class="header">
                <BackButton />
                <h3>Novo Cliente</h3>
            </div>
            <form @submit.prevent="CreateClient">
                <div class="input-group">
                    <label for="nome">Nome:</label>
                    <InputText v-model="nome" type="text" id="nome" placeholder="Nome Sobrenome" />
                </div>
                <ValidationError v-if="v$.nome.$error" :errors="v$.nome.$errors" />

                <div class="input-group">
                    <label for="email">E-mail:</label>
                    <InputText v-model="email" type="email" id="email" placeholder="exemplo@email.com" />
                </div>
                <ValidationError v-if="v$.email.$error" :errors="v$.email.$errors" />

                <div class="input-group">
                    <label for="password">Senha:</label>
                    <InputText v-model="password" type="password" id="password" placeholder="********" />
                </div>
                <ValidationError v-if="v$.password.$error" :errors="v$.password.$errors" />

                <div class="input-group">
                    <label for="password_confirmation">Confir. senha:</label>
                    <InputText v-model="password_confirmation" type="password" id="password_confirmation"
                        placeholder="********" />
                </div>
                <ValidationError v-if="v$.password_confirmation.$error" :errors="v$.password_confirmation.$errors" />

                <Button type="submit" id="create-btn" label="Cadastrar" severity="info" :disabled="btn_disabled" />
            </form>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import BackButton from '@/components/BackButton.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import ValidationError from '@/components/ValidationError.vue'
import http from '@/services/http.js'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators'

export default {
    name: 'ClienteCreate',
    components: {
        Navbar,
        InputText,
        Button,
        BackButton,
        ValidationError
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            nome: '',
            email: null,
            password: null,
            password_confirmation: null,
            btn_disabled: false
        }
    },

    validations() {
        return {
            nome: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(5)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(100)
                )
            },
            email: {
                required: helpers.withMessage('Campo obrigatório', required),
                email: helpers.withMessage('Deve ser um E-mail válido. Exemplo: email@exemplo.com', email)
            },
            password: {
                required: helpers.withMessage('Campo obrigatório', required),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(30)
                )
            },
            password_confirmation: {
                required: helpers.withMessage('Campo obrigatório', required),
                sameAsPassword: helpers.withMessage('Senhas não são iguais', sameAs(this.password)),
                minLength: helpers.withMessage(
                    ({ $params }) => `Este campo precisa ter no mínimo ${$params.min} caracteres`,
                    minLength(8)
                ),
                maxLength: helpers.withMessage(
                    ({ $params }) => `Este campo pode ter no máximo ${$params.min} caracteres`,
                    maxLength(30)
                ),

            }
        }
    },

    methods: {
        async CreateClient() {
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) return
            this.btn_disabled = true;

            if (this.password === this.password_confirmation) {
                try {
                    const payload = {
                        name: this.nome,
                        email: this.email,
                        password: this.password
                    };
                    await http.post('/user/', payload);
                    this.$router.push('/clientes');
                    this.$root.toast('Sucesso', 'Cliente criado com sucesso', 'success');
                    this.v$.$reset()
                } catch (error) {
                    if (error.response.data.detail === 'Email already in use') {
                        this.$root.toast('Ops...', 'Este e-mail já foi cadastrado', 'error');

                    }else{
                        this.$root.toast('Ops...', 'Não foi possível cadastrar o cliente', 'error');
                    }
                }
            } else {
                this.$root.toast('Ops...', 'Senhas não são iguais', 'error');
            }
        },
    }
}
</script>

<style scoped>
#content {
    display: flex;
    justify-content: center;
}

#form-container {
    width: 80%;
    margin-top: 50px;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.header h3 {
    flex: 1;
    text-align: center;
}

.input-group {
    margin-bottom: 15px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

input,
.p-password input {
    width: 75%;
}

#create-btn {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 90%;
}

@media only screen and (max-width: 910px) {

    input,
    select,
    .p-inputtext,
    .p-dropdown,
    .p-calendar,
    .p-multiselect,
    .p-inputnumber {
        width: 100%;
        text-align: start;
    }

    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    label {
        margin-bottom: 5px
    }

    #due-alert {
        margin-left: 0;
    }
}
</style>
