<template>
    <Dialog :visible="modelValue" header="Pagamento QR-Code" :modal="true" :style="{ width: '25rem' }"
        @update:visible="updateVisible">
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <template v-if="qrCodeLoading">
                <div style="text-align: center;">
                    <i class="pi pi-spin pi-spinner-dotted" style="font-size: 7em"></i>
                </div>
            </template>
            <template v-else>
                <div v-if="qrCode" style="text-align: center;">
                    <img :src="qrCode" alt="QR Code" draggable="false" />
                    <p><strong>Valor Total: </strong> {{ formatCurrency(qrAmount) }} </p>
                </div>
                <div v-else>
                    <h4>🙁 Ops...</h4>
                    <p>Não foi possível gerar o QR-Code</p>
                </div>
            </template>
        </div>
        <template #footer>
            <div>
                <Button label="Cancelar" severity="secondary" @click="closeDialog" icon="pi pi-times"
                    class="p-button-outlined p-button-sm" />
                <Button id="copy-paste" severity="success" label="Pix Copia e Cola" icon="pi pi-copy"
                    @click="pixCopyAndPaste(qrCode_CopyPaste)" class="p-button-sm" />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import http from '@/services/http.js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from "primevue/usetoast";

const toast = useToast();

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    row: {
        type: Object,
        default: null
    }
});

const emit = defineEmits(['update:modelValue']);

const qrCode = ref(null);
const qrCode_CopyPaste = ref(null);
const qrCodeLoading = ref(false);
const qrAmount = ref(null);

const updateVisible = (value) => {
    emit('update:modelValue', value);
};

const closeDialog = () => {
    emit('update:modelValue', false);
};

const pixCopyAndPaste = (text) => {
    const textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        toast.add({ severity: 'success', summary: 'Confirmação', detail: 'Pix Copia e Cola copiado para sua área de transferência', life: 3000 });
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Ops...', detail: 'Não foi possível copiar o Código Pix, tente novamente', life: 3000 });
    }
    document.body.removeChild(textArea);
};

const generateQRCode = async () => {
    qrCodeLoading.value = true;
    try {
        const response = await http.post(`/payment/generate/qr-code`, {
            public_ids: [props.row.debt_invoice]
        });
        qrCode_CopyPaste.value = response.data.pix_copy_and_paste;
        qrCode.value = response.data.base64_qrcode;
        qrAmount.value = response.data.pix_value;
        qrCodeLoading.value = false;
    } catch (error) {
        if (error.response?.data?.message === 'Debt instance is not pending') {
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Parece que essa dívida já foi paga.', life: 3000 });
            closeDialog();
        } else {
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Algo deu errado ao gerar o QR-Code', life: 3000 });
            qrCodeLoading.value = true;
        }
    }
};

const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
    return formatter.format(amount);
};

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        generateQRCode();
    } else {
        qrCode.value = null;
        qrCode_CopyPaste.value = null;
        qrCodeLoading.value = false;
    }
});

</script>

<style scoped>
button {
    margin-left: 15px;
    height: 40px;
}

#copy-paste {
    margin-left: 15px;
}
</style>
