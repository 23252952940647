<template>
    <Navbar />
    <div id="content">
        <div id="header">
            <h2 id="header-title">Clientes</h2>
            <div id="header-button">
                <router-link to="/cliente-new">
                    <Button label="Novo Cliente" severity="primary" />
                </router-link>
            </div>
        </div>

        <div class="card">
            <DataTable v-if="!loading" :value="users" :rows="pagination['clientes']" paginator
                :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem"
                @update:rows="updatePagination('clientes', $event)">

                <Column field="user_id" header="ID" sortable></Column>
                <Column field="user_name" header="Nome" sortable></Column>
                <Column field="user_email" header="E-mail" sortable></Column>
                <Column field="user_lastinteraction" header="Último Login" sortable></Column>
                <Column field="user_lastpayment" header="Último Pagamento" sortable></Column>
                <Column field="actions" header="Ações">
                    <template #body="slotProps">
                        <Button class="p-button-sm" label="Editar" icon="pi pi-user-edit"
                            @click="edit(slotProps.data.user_id)" />
                    </template>
                </Column>
            </DataTable>
            <div v-else>
                <DataTable :value="skeleton">
                    <Column field="user_id" header="ID">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="user_name" header="Nome">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="user_email" header="E-mail">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="user_lastinteraction" header="Último Login">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="user_lastpayment" header="Último Pagamento">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="actions" header="Ações">
                        <template #body>
                            <Skeleton width="2rem"></Skeleton>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';
import { getPagination, setPagination } from '@/services/pagination.js';

export default {
    name: 'Clientes',
    components: {
        Navbar,
        DataTable,
        Column,
        Button,
        Skeleton
    },
    data() {
        return {
            users: [],
            skeleton: Array.from({ length: 5 }, () => ({})),
            loading: true,

            pagination: {
                clientes: getPagination('clientes'),
            }
        };
    },
    methods: {
        async getUsers() {
            try {
                const response = await http.get('/user/');
                this.users = response.data.map(user => {
                    const formatted_interaction = user.last_interaction
                        ? new Date(user.last_interaction).toLocaleString('pt-br', { timeZone: 'America/Sao_Paulo' })
                        : 'Sem Info.';
                    const formatted_last_payment = user.last_payment
                        ? new Date(user.last_payment).toLocaleString('pt-br', { timeZone: 'America/Sao_Paulo' })
                        : 'Sem Info.';


                    return {
                        user_id: user.id,
                        user_name: user.name,
                        user_email: user.email,
                        user_lastinteraction: formatted_interaction,
                        user_lastpayment: formatted_last_payment
                    };
                });
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar clientes', 'error');
            } finally {
                this.loading = false;
            }
        },

        edit(id) {
            this.$router.push({ name: 'Editar Cliente', params: { id: id } });
        },

        updatePagination(key, value) {
            setPagination(key, value);
            this.pagination[key] = value;
        }
    },
    mounted() {
        this.getUsers();
    }
};
</script>

<style scoped>
#content {
    padding: 40px 5%;
}

#header {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#header-title {
    text-align: left;
    align-self: flex-end;
    padding: 0px 16px;
}

#header-button {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
}

button {
    margin-left: 15px;
    height: 40px;
}
</style>
