<template>
    <Menubar :model="visibleItems">
        <template #start>
            <Logo />
        </template>
        <template #item="{ item, props }">
            <a class="flex align-items-center" v-bind="props.action">
                <span :class="item.icon" />
                <span class="item-label">{{ item.label }}</span>
            </a>
        </template>
    </Menubar>
</template>

<script setup>
import { ref, computed } from 'vue';
import Menubar from 'primevue/menubar';
import { useRouter } from 'vue-router';
import { useAuth } from '@/stores/auth';
import Logo from './Logo.vue';


const router = useRouter();
const auth = useAuth();

const items = ref([
    {
        label: 'Home',
        icon: 'pi pi-home',
        command: () => router.push('/home'),
        permission: 'user'
    },
    {
        label: 'Minhas Dívidas',
        icon: 'pi pi-wallet',
        command: () => router.push('/dividas'),
        permission: 'user'
    },
    {
        label: 'Dashboard',
        icon: 'pi pi-cog',
        command: () => router.push('/dashboard'),
        permission: 'admin'
    },
    {
        label: 'Clientes',
        icon: 'pi pi-users',
        command: () => router.push('/clientes'),
        permission: 'admin'
    },
    {
        label: 'Dívidas',
        icon: 'pi pi-wallet',
        command: () => router.push('/adm-dividas'),
        permission: 'admin'
    },
    {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => {
            auth.clear();
            router.push('/login');
        }
    }
]);

const visibleItems = computed(() => {
    if (auth.isAuthenticated) {
        const userPermissions = auth.data.is_admin ? ['admin'] : ['user'];
        return items.value.filter(item => !item.permission || userPermissions.includes(item.permission));
    } else {
        return [];
    }
});

</script>

<style scoped>
.item-label {
    margin-left: 5px;
}

.p-menubar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}
</style>