import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import ClienteDebt from '@/views/ClienteDebt.vue'
import ClienteDebts from '@/views/ClienteDebts.vue'
import Login from '@/views/Login'
import Dashboard from '@/views/Dashboard'
import Clientes from '@/views/Clientes.vue'
import ClienteCreate from '@/views/ClienteCreate.vue'
import ClienteEdit from '@/views/ClienteEdit.vue'
import Dividas from '@/views/Dividas.vue'
import DividaCreate from '@/views/DividaCreate.vue'
import DividaEdit from '@/views/DividaEdit.vue'
import { useAuth } from '@/stores/auth'

const routes = [
    {
        path: '/',
        redirect: to => {
            const auth = useAuth();
            if (auth.token && auth.data.is_admin) {
                return '/dashboard';
            } else if (auth.token) {
                return '/home';
            } else {
                return '/login';
            }
        }
    },
    {
        // se a página nao existir, joga pra raiz
        path: '/:catchAll(.*)',
        redirect: '/'
    },

    {
        path: '/login',
        name: 'Entrar',
        component: Login
    },
    {
        path: '/home',
        name: 'Dashboard',
        component: Home,
        meta: {
            auth: true
        }
    },
    {
        path: '/dividas',
        name: 'Dívidas',
        component: ClienteDebts,
        meta: {
            auth: true
        }
    },
    {
        path: '/divida-details/:id',
        name: 'Detalhes da dívida',
        component: ClienteDebt,
        meta: {
            auth: true
        }
    },
    {
        path: '/dashboard',
        name: 'ADM Dashboard',
        component: Dashboard,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/cliente-new',
        name: 'Novo Cliente',
        component: ClienteCreate,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/cliente-edit/:id',
        name: 'Editar Cliente',
        component: ClienteEdit,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/adm-dividas',
        name: 'ADM Dívidas',
        component: Dividas,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/divida-new',
        name: 'Nova Dívida',
        component: DividaCreate,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
    {
        path: '/divida-edit/:id',
        name: 'Editar Dívida',
        component: DividaEdit,
        meta: {
            auth: true,
            requires_admin: true
        }
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    document.title = to.name;
    const auth = useAuth();

    try {
        if (to.meta?.auth) {
            if (auth.token) {
                const isAuthenticated = await auth.checkToken();

                if (isAuthenticated) {
                    if (to.meta.requires_admin) {
                        if (auth.data.is_admin) {
                            // Se for admin procede para a rota
                            if (to.path === '/') {
                                next({ path: '/dashboard' })
                            } else {
                                next();
                            }
                        } else {
                            // Se não for, redireciona para o user dashboard
                            next({ path: '/home' });
                        }
                    } else {
                        // se requer apenas estar autenticado, procede para a rota
                        if (to.path === '/') {
                            next({ path: '/home' })
                        } else {
                            next(); // Procede para a rota desejada
                        }
                    }
                } else {
                    // Token não é válido
                    auth.clear();
                    next({ path: '/login' });
                }
            } else {
                // Sem token
                next({ path: '/login' });
            }
        } else {
            next(); // Rota não requer autenticação, procede normalmente
        }
    } catch (error) {
        auth.clear();
        next({ name: 'Entrar' });
    }
});


export default router;
