<template>
    <Navbar />
    <div class="header">
        <BackButton />
        <h3>Visualizar Dívida</h3>
    </div>
    <div id="content">
        <TabView id="tabview">
            <TabPanel header="Dados">
                <div id="form-container">
                    <form @submit.prevent="UpdateDebt">
                        <div class="input-group">
                            <label for="desc">Descrição:</label>
                            <InputText v-model="desc" type="text" id="desc" placeholder="Descrição" readonly />
                        </div>

                        <div class="input-group">
                            <label for="amount">Valor:</label>
                            <InputNumber v-model="amount" inputId="minmaxfraction" :minFractionDigits="2"
                                :maxFractionDigits="2" placeholder="00,00" readonly />
                        </div>

                        <div class="input-group">
                            <label for="recurrence">Recorrência:</label>
                            <InputText v-model="selected_recurrence" type="text" placeholder="Tags" readonly />
                        </div>

                        <div class="input-group" v-show="selected_recurrence == 'installment'">
                            <label for="instance">Parcelas:</label>
                            <InputNumber v-model="instance" mode="decimal" showButtons :min="1" :max="30"
                                placeholder="1 a 30" readonly />
                        </div>

                        <div class="input-group">
                            <label for="emission">Início da Emissão:</label>
                            <Calendar v-model="emission" placeholder="dd/mm/aaaa" dateFormat="dd/mm/yy" readonly />
                        </div>

                        <div class="input-group">
                            <label for="due">Vencimento:</label>
                            <InputText v-model="due_day" mode="decimal" showButtons :min="1" :max="31"
                                placeholder="1 a 31" readonly />
                        </div>

                        <!-- <div class="input-group">
              <label for="fee">Juros:</label>
              <InputNumber v-model="fee" inputId="minmaxfraction" :minFractionDigits="2" :maxFractionDigits="2" required/>
            </div> -->

                    </form>
                </div>
            </TabPanel>
            <TabPanel header="Faturas">
                <div class="card">
                    <DataTable :value="instances" :rows="25" paginator :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem">
                        <Column field="debt_id" header="ID" sortable></Column>
                        <Column field="debt_invoice" header="Invoice" style="display: none"></Column>
                        <Column field="debt_desc" header="Descrição" sortable></Column>
                        <Column field="debt_amount" header="Valor" sortable>
                            <template #body="slotProps">
                                {{ formatCurrency(slotProps.data.debt_amount) }}
                            </template>
                        </Column>
                        <Column field="debt_due" header="Vencimento" sortable></Column>
                        <Column field="debt_status" header="Estado" sortable></Column>
                        <Column field="debt_author" header="Autor" style="display: none;"></Column>
                        <Column field="actions" header="Ações">
                            <template #body="slotProps">
                                <div class="card flex justify-content-center">
                                    <Button class="p-button-sm" label="Pagar" icon="pi pi-wallet" :disabled="slotProps.data.debt_status !== 'Pendente'"
                                        @click="qrDialog([slotProps.data.debt_invoice])" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
        </TabView>
    </div>
    <UserPaymentDialog v-model="qrDialogVisible" :row="row" @payment-success="getDebts" />

</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import BackButton from '@/components/BackButton.vue'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import MultiSelect from 'primevue/multiselect'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ButtonGroup from 'primevue/buttongroup'
import Dialog from 'primevue/dialog'
import UserPaymentDialog from '@/components/UserPaymentDialog.vue'

export default {
    name: 'ClientDebts',
    components: {
        Navbar,
        InputText,
        Checkbox,
        Button,
        InputNumber,
        Dropdown,
        Calendar,
        MultiSelect,
        TabPanel,
        TabView,
        DataTable,
        Column,
        ButtonGroup,
        Dialog,
        BackButton,
        UserPaymentDialog

    },
    data() {
        return {
            desc: null,
            amount: null,
            emission: null,
            due_day: null,
            instance: null,
            // fee: null,
            selected_client: null,
            selected_tag: [],
            tags: [
                { name: 'Pessoal' },
                { name: 'Empréstimo' },
                { name: 'NodeLake' }
            ],

            instances: [],
            debt_id: null,

            chargeDialogVisible: false,
            paidDialogVisible: false,
            qrDialogVisible: false,
        }
    },
    methods: {
        async getDebtData(id) {
            try {
                const response = await http.get(`/debt/${id}`);
                const debt = response.data;

                const formattedEmission = new Date(debt.start_at).toLocaleDateString('pt-br', { timeZone: 'UTC' });

                this.debt_id = debt.id;
                this.desc = debt.description;
                this.amount = debt.amount;
                this.selected_recurrence = this.formatRecurrence(debt.category_type);
                this.instance = debt.installment_count;
                this.emission = formattedEmission;
                this.due_day = debt.due_day;
                this.selected_client = debt.user.name;

                try {
                    this.instances = debt.instances.map(instance => {
                        const formatted_due = new Date(instance.due_at).toLocaleDateString('pt-br', { timeZone: 'UTC' });

                        return {
                            debt_id: instance.id,
                            debt_invoice: instance.public_id,
                            debt_desc: debt.description,
                            debt_amount: instance.amount,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(instance.status),
                            debt_author: debt.user.name
                        };
                    });
                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar dados das faturas', 'error');

                }
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar dados da dívida', 'error');
            }
        },

        async qrDialog(row) {
            if (!row) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente.', 'error');
                return;
            }

            this.qrCode_isPaid = false;
            this.qrDialogVisible = true;
            this.row = row;
        },

        formatCurrency(amount) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
            return formatter.format(amount);
        },

        formatRecurrence(recurrence) {
            switch (recurrence) {
                case 'once':
                    return 'Única';
                case 'recurrent':
                    return 'Mensal';
                case 'installment':
                    return 'Parcelado';
            }
        },

        formatStatusType(statusType) {
            switch (statusType) {
                case 'pending':
                    return 'Pendente';
                case 'paid':
                    return 'Pago';
                case 'cancelled':
                    return 'Cancelado';
            }
        },
    },
    mounted() {
        const id = this.$route.params.id;
        this.getDebtData(id);
    }
}

</script>

<style scoped>
#tabview {
    width: 80%;
}

#content {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 40px
}

.header .pi {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    margin-left: 10%;
}

.header h3 {
    flex: 1;
    text-align: center;
}

#form-container {
    width: 100%;
    margin-top: 20px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%
}

label {
    margin-right: 10%;
}

input,
select,
.p-inputtext,
.p-dropdown,
.p-calendar,
.p-multiselect,
.p-inputnumber {
    width: 70%;
    text-align: start;
}

#checkbox {
    display: block;
    text-align: left;
    margin-bottom: 15px;
    margin-top: -10px;
    margin-left: 32%;
}

#edit-btn {
    margin-top: 5px;
    margin-bottom: 25px;
    width: 90%;
}

li {
    list-style: none;
}

#copy-paste {
    margin-left: 8px
}

@media only screen and (max-width: 750px) {

    input,
    select,
    .p-inputtext,
    .p-dropdown,
    .p-calendar,
    .p-multiselect,
    .p-inputnumber {
        width: 100%;
        text-align: start;
    }

    .input-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    #checkbox {
        margin-left: 5%;
    }

    label {
        margin-bottom: 5px
    }
}
</style>