<template>
    <div class="card-list">
        <div class="card-item" v-for="card in cards" :key="card.title">
            <h3>{{ card.title }}</h3>
            <p>
                <span v-if="card.emoji" style="font-size: 1.4em; margin-right: 1px;">{{ card.emoji }}</span>{{
                card.value }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardList',
    props: {
        cards: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>
.card-list {
    display: flex;
    flex-direction: row;
}

.card-item p {
    font-weight: bold;
    margin: 0
}

h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 23.5%;
    min-height: 110px;
    padding: 10px;
    margin: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.card-item p {
    font-weight: bold;
    margin: 0;
}

@media only screen and (max-width: 920px) {
    .card-list {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: center;
    }

    .card-item {
        width: 45%;
        height: 80px;
        padding: 5px;
        margin: 10px;
    }
}

@media only screen and (max-width: 520px) {
    .card-list {
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    .card-item {
        min-width: 80%;
    }
}
</style>
