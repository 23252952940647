<template>
    <Navbar />
    <div id="content">
        <div id="header">
            <h2 id="header-title">Dívidas</h2>
            <div id="header-button">
                <router-link to="/divida-new">
                    <Button id="new-debt" label="Nova Dívida" severity="primary" />
                </router-link>
            </div>
        </div>

        <div class="card">
            <DataTable v-if="!loading" :value="filtered_debts" :rows="pagination['dividas']" paginator
                :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} a {last} de {totalRecords}" tableStyle="min-width: 50rem"
                @update:rows="updatePagination('dividas', $event)">
                <template #header>
                    <div id="filters">
                        <Calendar id="month-select" v-model="selected_date" view="month" dateFormat="mm/yy"
                            placeholder="01/2024" showIcon :showOnFocus="false" iconDisplay="input" />

                        <Dropdown v-model="selected_recurrence" :options="recurrences" optionLabel="name"
                            optionValue="code" placeholder="Recorrência" class="w-full md:w-14rem" required />

                        <Dropdown v-model="selected_status" :options="status" optionLabel="name" optionValue="code"
                            placeholder="Estado" class="w-full md:w-14rem" required />

                        <Dropdown v-model="selected_client" :options="clients" filter optionLabel="name"
                            optionValue="user_id" placeholder="Autor" class="w-full md:w-14rem" required />

                        <InputText v-model="search" placeholder="Pesquisar" @input="searchData"/>
                        <div>
                            <Button icon="pi pi-search" @click="getDebts" id="search-btn" />
                            <Button icon="pi pi-trash" severity="danger" @click="clearFilters" />
                        </div>
                    </div>

                </template>
                <Column field="debt_id" header="ID" sortable></Column>
                <Column field="debt_description" header="Descrição" sortable></Column>
                <Column field="debt_name" header="Autor" sortable></Column>
                <Column field="debt_category_type" header="Recorrência" sortable></Column>
                <Column field="debt_start_at" header="Emissão" sortable></Column>
                <Column field="debt_amount" header="Valor" sortable>
                    <template #body="slotProps">
                        {{ formatCurrency(slotProps.data.debt_amount) }}
                    </template>
                </Column>
                <Column field="debt_status" header="Estado" sortable></Column>
                <Column field="actions" header="Ações">
                    <template #body="slotProps">
                        <Button class="p-button-sm" label="Editar" icon="pi pi-pen-to-square"
                            @click="edit(slotProps.data.debt_id)" />
                    </template>
                </Column>
            </DataTable>
            <div v-else>
                <DataTable :value="skeleton">
                    <Column field="debt_id" header="ID">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_description" header="Descrição">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_name" header="Autor">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_category_type" header="Recorrência">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_start_at" header="Emissão">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_amount" header="Valor">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="debt_status" header="Estado">
                        <template #body>
                            <Skeleton></Skeleton>
                        </template>
                    </Column>
                    <Column field="actions" header="Ações">
                        <template #body>
                            <Skeleton width="2rem"></Skeleton>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Skeleton from 'primevue/skeleton';
import { getPagination, setPagination } from '@/services/pagination.js';
import InputText from 'primevue/inputtext';

export default {
    name: 'Debts',
    components: {
        Navbar,
        DataTable,
        Column,
        Button,
        Dropdown,
        Calendar,
        Skeleton,
        InputText
    },
    data() {
        return {
            debts: [],
            filtered_debts: [],
            search: '',
            skeleton: Array.from({ length: 5 }, () => ({})),
            loading: true,
            selected_date: null,
            selected_client: null,
            clients: [],
            selected_recurrence: null,
            recurrences: [
                { name: 'Única', code: 'once' },
                { name: 'Mensal', code: 'recurrent' },
                { name: 'Parcelado', code: 'installment' },
            ],
            selected_status: null,
            status: [
                { name: 'Pendente', code: 'pending' },
                { name: 'Pago', code: 'paid' },
                { name: 'Cancelado', code: 'cancelled' },
            ],

            pagination: {
                dividas: getPagination('dividas'),
            }
        }
    },
    methods: {
        async getDebts() {
            if (this.selected_date instanceof Date) {
                this.loading = true;
                try {
                    let queryParams = [];

                    const month = String(this.selected_date.getMonth() + 1).padStart(2, '0');
                    const year = String(this.selected_date.getFullYear());
                    queryParams.push(`date_year=${year}&date_month=${month}`);

                    if (this.selected_recurrence) {
                        queryParams.push(`category_type=${this.selected_recurrence}`);
                    }
                    if (this.selected_status) {
                        queryParams.push(`status=${this.selected_status}`);
                    }
                    if (this.selected_client) {
                        queryParams.push(`user_id=${this.selected_client}`);
                    }

                    const queryString = queryParams.join('&');
                    const endpoint = `/debt${queryString ? `?${queryString}` : ''}`;

                    const response = await http.get(endpoint);
                    this.debts = response.data.map(debt => {

                        const formatted_start = new Date(debt.start_at).toLocaleDateString('pt-br', { timeZone: 'UTC' });

                        return {
                            debt_id: debt.id,
                            debt_description: debt.description,
                            debt_amount: debt.amount,
                            debt_category_type: this.formatCategoryType(debt.category_type),
                            debt_start_at: formatted_start,
                            debt_status: this.formatStatusType(debt.status),
                            debt_name: debt.user.name
                        };
                    });
                    this.filtered_debts = this.debts;
                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar dívidas', 'error');
                } finally {
                    this.loading = false;
                }
            } else {
                this.$root.toast('Ops...', 'Selecione uma data válida', 'error');
            }
        },
        clearFilters() {
            this.selected_client = null;
            this.selected_recurrence = null;
            this.selected_status = null;
            const currentDate = new Date();
            this.selected_date = currentDate;

            this.getDebts();
        },
        async getUsers() {
            try {
                const response = await http.get('/user/');
                this.clients = response.data.map(user => ({
                    user_id: user.id,
                    name: user.name
                }));
            } catch (error) {
                this.$root.toast('Ops...', 'Não foi possível recuperar clientes', 'error');
            }
        },
        edit(id) {
            this.$router.push({ name: 'Editar Dívida', params: { id: id } });
        },

        searchData() {
            if (this.search) {
                this.filtered_debts = this.debts.filter(debt =>
                    Object.values(debt).some(value =>
                        value.toString().toLowerCase().includes(this.search.toLowerCase())
                    )
                );
            } else {
                this.filtered_debts = this.debts;
            }
        },

        formatCurrency(amount) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
            return formatter.format(amount);
        },
        formatCategoryType(categoryType) {
            switch (categoryType) {
                case 'once':
                    return 'Única';
                case 'recurrent':
                    return 'Mensal';
                case 'installment':
                    return 'Parcelado';
            }
        },
        formatStatusType(statusType) {
            switch (statusType) {
                case 'pending':
                    return 'Pendente';
                case 'paid':
                    return 'Pago';
                case 'cancelled':
                    return 'Cancelado';
            }
        },

        updatePagination(key, value) {
            setPagination(key, value);
            this.pagination[key] = value;
        }
    },
    mounted() {
        const currentDate = new Date();
        this.selected_date = currentDate;
        this.getDebts();
        this.getUsers();
    }
}
</script>

<style scoped>
#content {
    padding: 40px 5%;
}

#header {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#header-title {
    text-align: left;
    align-self: flex-end;
    padding: 0px 16px;
}

#header-button {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-wrap: wrap;
}

#header-button {
    display: flex;
    justify-content: flex-end;
}

#new-debt {
    margin-left: 15px;
    height: 40px;
}

#filters {
    text-align: left;
    display: flex;
}

input,
select,
.p-inputtext,
.p-dropdown,
.p-calendar,
.p-multiselect,
#search-btn {
    margin-right: 10px;
}

@media only screen and (max-width: 800px) {
    #filters {
        display: flex;
        flex-wrap: wrap;
    }

    input,
    select,
    .p-inputtext,
    .p-dropdown,
    .p-calendar,
    .p-multiselect {
        margin-right: 10px;
        margin-bottom: 5px;
    }
}
</style>
