<template>
    <Navbar />
    <div id="content">
        <div id="welcome">
            <div id="welcome-title">
                <h3>Olá, {{ $user.name }}!</h3>
            </div>
            <div id="month-select">
                <Calendar @date-select="getReports" v-model="selected_date" view="month" dateFormat="mm/yy" placeholder="Selecione o mês" showIcon
                    :showOnFocus="false" />
            </div>
        </div>

        <Message severity="error" v-show="pay_warning" :closable="false">Você tem faturas atrasadas. Pague imediatamente
            para evitar o
            corte
            dos serviços.</Message>

        <CardList :cards="cardData" />

        <TabView id="tabview">
            <TabPanel>
                <template #header>
                    <div class="p-overlay-badge">
                        <span>Dívidas do Mês</span>
                    </div>
                </template>
                <div id="debt-table">
                    <div class="card">
                        <DataTable v-show="faturas.length > 0 && !loading" :value="filtered_faturas" :rows="pagination['user_debts']" paginator
                            :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            @update:rows="updatePagination('user_debts', $event)" tableStyle="min-width: 50rem">
                            <template #header>
                                <SearchInput v-model="search" @button-click="payAll(filtered_faturas)"
                                    :buttonId="'pay-all'" :buttonLabel="'Pagar tudo'" :buttonSeverity="'warning'"
                                    :icon="'pi pi-wallet'" />
                            </template>
                            <Column field="debt_id" header="ID" sortable></Column>
                            <Column field="debt_invoice" header="Invoice" sortable style="display: none;"></Column>
                            <Column field="debt_desc" header="Descrição" sortable></Column>
                            <Column field="debt_emission" header="Emissão" sortable></Column>
                            <Column field="debt_due" header="Vencimento" sortable></Column>
                            <Column field="debt_status" header="Estado" sortable></Column>
                            <Column field="debt_amount" header="Valor" sortable>
                                <template #body="slotProps">
                                    {{ formatCurrency(slotProps.data.debt_amount) }}
                                </template>
                            </Column>
                            <Column field="debt_actions" header="Ações">
                                <template #body="slotProps">
                                    <div class="card flex justify-content-center">
                                        <Button class="p-button-sm" label="Pagar" icon="pi pi-wallet"
                                            @click="qrDialog([slotProps.data.debt_invoice])" />
                                    </div>
                                </template>
                            </Column>
                        </DataTable>

                        <div v-show="loading">
                            <DataTable :value="skeleton">
                                <Column field="debt_id" header="ID">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_desc" header="Descrição">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_emission" header="Emissão">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_due" header="Vencimento">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_status" header="Estado">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_amount" header="Valor">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                                <Column field="debt_actions" header="Ações">
                                    <template #body>
                                        <Skeleton></Skeleton>
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                        <div v-show="is_debt_empty">
                            <h4>Nenhuma pendência, incrível!</h4>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="p-overlay-badge">
                        <span style="padding-right: 10px;">Dívidas Atrasadas</span>
                        <DueBadge :count="faturas_atrasadas.length" v-show="is_debt_overdue" />
                    </div>
                </template>
                <div id="debt-table">

                    <div class="card" v-show="is_debt_overdue">
                        <DataTable :value="filtered_faturas_atrasadas" :rows="pagination['user_debts_overdue']" paginator
                            :rowsPerPageOptions="[20, 50, 100, 200, 1000]"
                            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} a {last} de {totalRecords}"
                            @update:rows="updatePagination('user_debts_overdue', $event)" tableStyle="min-width: 50rem">
                            <template #header>
                                <SearchInput v-model="search_overdue" @button-click="payAll(filtered_faturas_atrasadas)"
                                    :buttonId="'pay-all'" :buttonLabel="'Pagar tudo'" :buttonSeverity="'warning'"
                                    :icon="'pi pi-wallet'" />
                            </template>
                            <Column field="debt_id" header="ID" sortable></Column>
                            <Column field="debt_invoice" header="Invoice" sortable style="display: none;"></Column>
                            <Column field="debt_desc" header="Descrição" sortable></Column>
                            <Column field="debt_emission" header="Emissão" sortable></Column>
                            <Column field="debt_due" header="Vencimento" sortable></Column>
                            <Column field="debt_status" header="Estado" sortable></Column>
                            <Column field="debt_amount" header="Valor" sortable>
                                <template #body="slotProps">
                                    {{ formatCurrency(slotProps.data.debt_amount) }}
                                </template>
                            </Column>
                            <Column field="debt_actions" header="Ações">
                                <template #body="slotProps">
                                    <div class="card flex justify-content-center">
                                        <Button class="p-button-sm" label="Pagar" icon="pi pi-wallet" :disabled="slotProps.data.debt_status !== 'Pendente'"
                                            @click="qrDialog([slotProps.data.debt_invoice])" />
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div v-show="!is_debt_overdue">
                        <h4>Nenhuma pendência, incrível!</h4>
                    </div>
                </div>
            </TabPanel>
        </TabView>

        <UserPaymentDialog v-model="qrDialogVisible" :row="row" @payment-success="getReports" />


    </div>
</template>

<script>
import http from '@/services/http.js'
import Navbar from '@/components/Navbar.vue'
import UserPaymentDialog from '@/components/UserPaymentDialog.vue'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import ConfirmPopup from 'primevue/confirmpopup'
import Skeleton from 'primevue/skeleton';
import Message from 'primevue/message';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Badge from 'primevue/badge';
import CardList from '@/components/CardList.vue';
import DueBadge from '@/components/DueBadge.vue';
import SearchInput from '@/components/SearchInput.vue';
import { getPagination, setPagination } from '@/services/pagination.js';


let invoiceList = [];

export default {
    name: 'Home',
    components: {
        Navbar,
        Dropdown,
        DataTable,
        Column,
        Button,
        Calendar,
        ConfirmDialog,
        Dialog,
        ConfirmPopup,
        Skeleton,
        Message,
        TabView,
        TabPanel,
        UserPaymentDialog,
        Badge,
        CardList,
        DueBadge,
        SearchInput
    },
    data() {
        return {
            selected_date: null,
            debt_state: null,
            month_amount: null,
            next_month: null,
            late_amount: null,
            pay_warning: null,

            faturas: [],
            filtered_faturas: [],
            search: '',

            faturas_atrasadas: [],
            filtered_faturas_atrasadas: [],
            search_overdue: '',

            loading: true,
            skeleton: Array.from({ length: 5 }, () => ({})),
            is_debt_empty: false,
            is_debt_overdue: false,
            emoji: null,

            qrDialogVisible: false,
            row: null,

            pagination: {
                user_debts: getPagination('user_debts'),
                user_debts_overdue: getPagination('user_debts_overdue'),
            }
        }
    },

    computed: {
        cardData() {
            return [
                {
                    title: 'Estado',
                    value: this.debt_state,
                    emoji: this.emoji
                },
                {
                    title: 'Dívida do Mês',
                    value: this.month_amount
                },
                {
                    title: 'Próximo Mês',
                    value: this.next_month
                },
                {
                    title: 'Dívida Atrasada',
                    value: this.late_amount
                }
            ];
        }
    },

    methods: {
        async getReports() {
            if (this.selected_date instanceof Date) {
                const month = String(this.selected_date.getMonth() + 1).padStart(2, '0');
                const year = String(this.selected_date.getFullYear());
                const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

                try {
                    const response = await http.get(`/report/user/resume?date_year=${year}&date_month=${month}`);
                    this.debt_state = this.formatReportStatus(response.data.debt_state);
                    this.month_amount = formatter.format(response.data.debt_month_amount);
                    this.next_month = formatter.format(response.data.debt_next_month_amount);
                    this.late_amount = formatter.format(response.data.debt_total_amount);
                    this.pay_warning = response.data.pay_warning;

                    await this.getDebts();
                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar o Report', 'error');
                }
            } else {
                this.$root.toast('Ops...', 'Selecione uma data válida', 'error');
            }
        },

        async getDebts() {
            this.loading = true;
            this.is_debt_empty = false;
            if (this.selected_date instanceof Date) {
                const month = String(this.selected_date.getMonth() + 1).padStart(2, '0');
                const year = String(this.selected_date.getFullYear());

                try {
                    const response = await http.get(`/debt/instances/me?status=pending&date_year=${year}&date_month=${month}`);
                    this.faturas = response.data.map(fatura => {

                        const formatted_start = new Date(fatura.debt.start_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });
                        const formatted_due = new Date(fatura.due_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });

                        return {
                            debt_id: fatura.id,
                            debt_invoice: fatura.public_id,
                            debt_desc: fatura.debt.description,
                            debt_emission: formatted_start,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(fatura.status),
                            debt_amount: fatura.amount,
                        };
                    });
                    this.is_debt_empty = this.faturas.length === 0;
                    this.filtered_faturas = this.faturas;
                    this.loading = false;

                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar as dívidas', 'error');
                }

                try {
                    const response = await http.get(`/debt/instances/me?overdue=true&status=pending`);
                    this.faturas_atrasadas = response.data.map(fatura_atrasada => {

                        const formatted_start = new Date(fatura_atrasada.debt.start_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });
                        const formatted_due = new Date(fatura_atrasada.due_at).toLocaleDateString('pt-br', { timeZone: 'America/Sao_Paulo' });

                        return {
                            debt_id: fatura_atrasada.id,
                            debt_invoice: fatura_atrasada.public_id,
                            debt_desc: fatura_atrasada.debt.description,
                            debt_emission: formatted_start,
                            debt_due: formatted_due,
                            debt_status: this.formatStatusType(fatura_atrasada.status),
                            debt_amount: fatura_atrasada.amount,
                        };
                    });
                    this.is_debt_overdue = this.faturas_atrasadas.length > 0;
                    this.loading = false;
                    this.filtered_faturas_atrasadas = this.faturas_atrasadas;

                } catch (error) {
                    this.$root.toast('Ops...', 'Não foi possível recuperar as dívidas atrasadas', 'error');
                }
            } else {
                this.$root.toast('Ops...', 'Selecione uma data válida', 'error');
            }

        },

        async payAll(debts) {
            try {
                invoiceList = [];
                invoiceList = debts.map(fatura => fatura.debt_invoice);
                this.qrDialog(invoiceList);
            } catch (error) {
                this.$root.toast('Ops...', 'Algo deu errado ao pagar todas as dívidas', 'error');
            }
        },

        async qrDialog(row) {
            if (!row) {
                this.$root.toast('Erro', 'Fatura inválida. Por favor, tente novamente.', 'error');
                return;
            }

            this.qrCode_isPaid = false;
            this.qrDialogVisible = true;
            this.row = row;
        },

        searchData() {
            if (this.search) {
                this.filtered_faturas = this.faturas.filter(fatura =>
                    Object.values(fatura).some(value =>
                        value.toString().toLowerCase().includes(this.search.toLowerCase())
                    )
                );
            } else {
                this.filtered_faturas = this.faturas;
            }
        },

        searchOverdueData() {
            if (this.search_overdue) {
                this.filtered_faturas_atrasadas = this.faturas_atrasadas.filter(fatura_atrasada =>
                    Object.values(fatura_atrasada).some(value =>
                        value.toString().toLowerCase().includes(this.search_overdue.toLowerCase())
                    )
                );
            } else {
                this.filtered_faturas_atrasadas = this.faturas_atrasadas;
            }
        },

        formatReportStatus(statusType) {
            switch (statusType) {
                case 'up_to_date':
                    this.emoji = '😇';
                    return 'Em dia';

                case 'pending':
                    this.emoji = '🙁';
                    return 'Pendente';

                case 'delayed':
                    this.emoji = '😖';
                    return 'Atrasado';
            }
        },

        formatStatusType(statusType) {
            switch (statusType) {
                case 'pending':
                    return 'Pendente';
                case 'paid':
                    return 'Pago';
                case 'cancelled':
                    return 'Cancelado';
            }
        },

        formatCurrency(amount) {
            const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
            return formatter.format(amount);
        },

        updatePagination(key, value) {
            setPagination(key, value);
            this.pagination[key] = value;
        }
    },

    watch: {
        search(newValue) {
            this.searchData();
        },
        search_overdue(newValue) {
            this.searchOverdueData();
        },
    },
    mounted() {
        const currentDate = new Date();
        this.selected_date = currentDate;
        this.getReports();
    }
}
</script>


<style scoped>
#content {
    padding: 40px 5%;
}

#welcome {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 30px;
}

#welcome-title {
    flex: 1;
    text-align: center;
}

#month-select {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    width: 200px;
    margin-right: 20px
}

#tabview {
    padding: 15px;
    margin-top: 15px;
}

#fee {
    font-size: x-small;
}

#debt-table {
    padding: 15px;
}

#debt-header {
    display: flex;
    justify-content: flex-end;

}

.p-dropdown {
    height: 40px;
}

@media only screen and (max-width: 800px) {
    #content {
        padding: 10px;
    }

    #welcome {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-bottom: 0px;
    }

    #welcome-title {
        flex: 1;
        text-align: center;
    }

    #month-select {
        cursor: pointer;
        font-size: 1.5rem;
        position: relative;
        right: 0;
        width: 200px;
    }

    #tabview {
        width: 100%;
        padding: 5px;
        margin-top: 30px;
    }

    #debt-table {
        padding: 5px;
    }

    #header-buttons {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-items: flex-end;
        display: flex;
        align-items: flex-end
    }

    #header-title {
        display: flex;
        align-items: flex-end;
    }

    #header-title h4 {
        margin-bottom: 0px;
    }

    button {
        margin-bottom: 10px;
        width: 120px;
    }
}
</style>
