<template>
    <Dialog :visible="modelValue" :header="qrCode_isCancelled ? '🙁 Ops...' : 'Pagamento Pix'" :modal="true" :style="{ width: '25rem' }"
        @update:visible="updateVisible">
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <template v-if="qrCodeLoading">
                <div style="text-align: center;">
                    <i class="pi pi-spin pi-spinner-dotted" style="font-size: 7em"></i>
                </div>
            </template>
            <template v-if="!qrCodeLoading && !qrCode_isPaid">
                <div v-if="qrCode" style="text-align: center;">
                    <img :src="qrCode" alt="QR Code" draggable="false" />
                    <p><strong>Valor Total: </strong> {{ formatCurrency(qrAmount) }} </p>
                </div>
                <div v-else-if="qrCode_isCancelled == true">
                    <p>Dívida não encontrada, tente gerar o QR-Code novamente</p>                  
                </div>
                <div v-else>
                    <p>Não foi possível gerar o QR-Code, tente novamente mais tarde</p>
                </div>
            </template>
            <template v-if="qrCode_isPaid">
                <div style="text-align: center;">
                    <i class="pi pi-check-circle" style="font-size: 7em; color: green"></i>
                    <h1>Recebemos seu pagamento com sucesso!</h1>
                </div>
            </template>
        </div>
        <template #footer>
            <div v-if="!qrCode_isPaid && !qrCode_isCancelled">
                <Button label="Cancelar" severity="secondary" @click="closeDialog" icon="pi pi-times"
                    class="p-button-outlined p-button-sm" />
                <Button id="copy-paste" severity="success" label="Pix Copia e Cola" icon="pi pi-copy"
                    @click="pixCopyAndPaste(qrCode_CopyPaste)" class="p-button-sm" />
            </div>
            <div v-else>
                <Button label="Fechar" severity="secondary" @click="closeDialog" icon="pi pi-times"
                    class="p-button-outlined p-button-sm" />
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import http from '@/services/http.js';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from "primevue/usetoast";

const toast = useToast();

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    row: {
        type: Object,
        default: null
    }
});

const emit = defineEmits(['update:modelValue', 'payment-success']);

const qrCode = ref(null);
const qrCode_CopyPaste = ref(null);
const qrCodeLoading = ref(false);
const qrCode_isPaid = ref(false);
const qrCode_isCancelled = ref(false);
const qrPaymentId = ref(null);
const qrAmount = ref(null);
let timer = null;
let isFocused = true;

const updateVisible = (value) => {
    emit('update:modelValue', value);
};

const closeDialog = () => {
        emit('update:modelValue', false);
        clearTimeout(timer);
        emit('payment-success');
};

const generateQRCode = async () => {
    qrCode_isPaid.value = false;
    qrCodeLoading.value = true;
    try {
        const response = await http.post(`/payment/generate/qr-code`, {
            public_ids: props.row
        });
        qrCode_CopyPaste.value = response.data.pix_copy_and_paste;
        qrCode.value = response.data.base64_qrcode;
        qrCodeLoading.value = false;
        qrPaymentId.value = response.data.payment_id;
        qrAmount.value = response.data.pix_value;
        timer = setTimeout(checkPixStatus, 5000);
    } catch (error) {
        if (error.response.data.detail.message === 'Debt instance is not pending') {
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Parece que essa dívida já foi paga.', life: 3000 });
            closeDialog();
            emit('payment-success');
        } else {
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Algo deu errado ao gerar o QR-Code', life: 3000 });
            qrCodeLoading.value = true;
        }
    }
};

const checkPixStatus = async () => {
    if (props.modelValue && isFocused) {
        try {
            const response = await http.get(`/payment/check?payment_id=${qrPaymentId.value}`);
            const pixStatus = response.data.pix_status;
            if (pixStatus === "pending") {
                timer = setTimeout(checkPixStatus, 5000);
            } else if (pixStatus === "paid") {
                qrCode_isPaid.value = true;
                qrCodeLoading.value = false;    
                qrCode.value = null;
                qrCode_CopyPaste.value = null;
                emit('payment-success');
            }else if (pixStatus === "cancelled"){
                qrCode_isCancelled.value = true;
                qrCode_isPaid.value = false;
                qrCodeLoading.value = false;    
                qrCode.value = null;
                qrCode_CopyPaste.value = null;
            }
        } catch (error) {
            clearTimeout(timer);
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Algo deu errado ao verificar o status do PIX\nApós o pagamento, recarregue a página.', life: 3000 });
            qrCodeLoading.value = false;
        }
    }
};

const pixCopyAndPaste = (text) => {
    const textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        if (textArea.value != '') {
            document.execCommand('copy');
            toast.add({ severity: 'success', summary: 'Confirmação', detail: 'Pix Copia e Cola copiado para sua área de transferência', life: 3000 });
        } else {
            toast.add({ severity: 'error', summary: 'Ops...', detail: 'Não foi possível copiar o Código Pix, tente novamente', life: 3000 });
        }
    } catch (err) {
        toast.add({ severity: 'error', summary: 'Ops...', detail: 'Não foi possível copiar o Código Pix, tente novamente', life: 3000 });
    }
    document.body.removeChild(textArea);
};

const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', });
    return formatter.format(amount);
};

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        generateQRCode();
    } else {
        qrCode.value = null;
        qrCode_CopyPaste.value = null;
        qrCodeLoading.value = false;
        qrCode_isPaid.value = false;
        clearTimeout(timer);
    }
});
</script>

<style scoped>
button {
    margin-left: 15px;
    height: 40px;
}
</style>
