<template>
    <router-view />
    <template>
        <div class="card flex justify-content-center">
            <Toast />
        </div>
    </template>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Toast from 'primevue/toast';
export default {
    components: {
        Navbar,
        Toast
    },
    methods: {
        toast(summary, detail, severity = 'info', life = 3000) {
            this.$toast.add({ severity, summary, detail, life });
        }
    }
}
</script>

<style>
body {
    margin: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
