<template>
    <div :class="logoContainerClass" @click="goHome">
        <span id="basta">Basta</span>
        <span id="pagar">Pagar</span>
    </div>
</template>

<script>
export default {
    name: 'Logo',
    computed: {
        logoContainerClass() {
            return {
                'margin': true,
                'no-pointer': this.$router.currentRoute.value.path === '/login'
            };
        }
    },
    methods: {
        goHome() {
            if (this.$router.currentRoute.value.path !== '/login') {
                if (this.$user.is_admin) {
                    this.$router.push('/dashboard');
                } else {
                    this.$router.push('/home');
                }
            }
        }
    }
};
</script>

<style scoped>
span {
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.margin {
    margin: 20px;
}

.no-pointer span {
    cursor: default;
}

#basta {
    background: linear-gradient(to right, #ff8100, #fdab03);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

#pagar {
    background: linear-gradient(to right, #189644, #48af45);
    -webkit-text-fill-color: transparent;
    background-clip: text;
}
</style>
