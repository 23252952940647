<template>
    <Badge class="p-badge p-component p-badge-no-gutter p-badge-danger" unstyled>
        {{ count }}
    </Badge>
</template>

<script>
import Badge from 'primevue/badge';

export default {
    name: 'DueBadge',
    components: {
        Badge,
    },
    props: {
        count: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style scoped>
.p-badge {
    background: #ef4444;
    color: #ffffff;
    font-size: 0.70rem;
    font-weight: 700;
    min-width: 1.2rem;
    height: 1.2rem;
    line-height: 1.2rem;
}
</style>