<template>
    <span class="pi pi-angle-left go-back-button" @click="goBack"></span>
</template>

<script>
export default {
    name: 'BackButton',
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.go-back-button {
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    margin-left: 5%;
}
</style>